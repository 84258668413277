import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

export default function RecursivePages(props) {
  let data = props.data;
  const selected = props.page.own != 1 ? props.page.selected : true;
  let topic = false;
  if (props.page.parent == 1 || props.page.parent == 889) {
    topic = true;
  }
  const hasChildren = props.page.children?.length > 0;
  const dragIcon = <DragIndicatorIcon />;

  if (!selected) {
    return <></>;
  } else
    return (
      <Accordion
        expandIcon={<ExpandMoreIcon />}
        defaultExpanded={topic ? true : false}
      >
        {topic ? (
          <>
            {hasChildren ? (
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                onDragStart={(e) => {
                  props.dragStart(e, props.page.id);
                }}
                onDragEnter={(e) => {
                  props.dragEnter(e, props.page.id);
                  e.target.style.opacity = "0.5";
                  e.currentTarget.style.backgroundColor = "lightblue";
                }}
                onDragLeave={(e) => {
                  e.target.style.opacity = "1";
                  e.currentTarget.style.backgroundColor = "white";
                }}
                onDrop={(e) => {
                  props.drop();
                  e.target.style.opacity = "1";
                  e.currentTarget.style.backgroundColor = "white";
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.currentTarget.style.backgroundColor = "lightblue";
                }}
                draggable
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {dragIcon}
                  <Typography
                    variant="h4"
                    id={props.page.id}
                    gutterBottom
                    style={{
                      marginLeft: ".5em",
                      marginTop: ".6em",
                    }}
                  >
                    {props.page.label}
                  </Typography>
                </Box>
              </AccordionSummary>
            ) : (
              <AccordionDetails
                style={{ cursor: "pointer" }}
                id={props.page.id}
                expandIcon={<ImageSearchIcon />}
                onDragStart={(e) => {
                  props.dragStart(e, props.page.id);
                }}
                onDragEnter={(e) => {
                  props.dragEnter(e, props.page.id);
                  e.target.style.opacity = "0.5";
                  e.currentTarget.style.backgroundColor = "lightblue";
                }}
                onDragLeave={(e) => {
                  e.target.style.opacity = "1";
                  e.currentTarget.style.backgroundColor = "white";
                }}
                onDrop={(e) => {
                  props.drop();
                  e.target.style.opacity = "1";
                  e.currentTarget.style.backgroundColor = "white";
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.currentTarget.style.backgroundColor = "lightblue";
                }}
                draggable
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {dragIcon}
                  <Typography
                    variant={props.page.own == 1 ? "h4" : "h6"}
                    id={props.page.id}
                    key={props.page.id}
                    style={{
                      marginLeft: props.page.own == 1 ? ".5em" : "2em",
                      width: "100%",
                      marginTop: ".3em",
                    }}
                  >
                    {props.page.label}
                  </Typography>
                  {props.page.own == 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        props.setCurrentSelected((current) =>
                          current.filter(
                            (element) => element.id != props.page.id
                          )
                        );
                        props.jsonGateway.api.removeAdditionalChapter(
                          props.page.id
                        );
                        document.getElementById(props.page.id).remove();
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </Box>
              </AccordionDetails>
            )}
          </>
        ) : (
          <>
            {hasChildren ? (
              <>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  onDragStart={(e) => {
                    props.dragStart(e, props.page.id);
                  }}
                  onDragEnter={(e) => {
                    props.dragEnter(e, props.page.id);
                    e.target.style.opacity = "0.5";
                    e.currentTarget.style.backgroundColor = "lightblue";
                  }}
                  onDragLeave={(e) => {
                    e.target.style.opacity = "1";
                    e.currentTarget.style.backgroundColor = "white";
                  }}
                  onDrop={(e) => {
                    props.drop();
                    e.target.style.opacity = "1";
                    e.currentTarget.style.backgroundColor = "white";
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.currentTarget.style.backgroundColor = "lightblue";
                  }}
                  draggable
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {dragIcon}
                    <Typography
                      variant="h5"
                      key={props.page.id}
                      style={{
                        marginTop: ".3em",
                        marginLeft: "1em",
                      }}
                    >
                      {props.page.label}
                    </Typography>
                  </Box>
                </AccordionSummary>
              </>
            ) : (
              <>
                <AccordionDetails
                  expandIcon={<ImageSearchIcon />}
                  style={{ cursor: "pointer" }}
                  onDragStart={(e) => {
                    props.dragStart(e, props.page.id);
                  }}
                  onDragEnter={(e) => {
                    props.dragEnter(e, props.page.id);
                    e.target.style.opacity = "0.5";
                    e.currentTarget.style.backgroundColor = "lightblue";
                  }}
                  onDragLeave={(e) => {
                    e.target.style.opacity = "1";
                    e.currentTarget.style.backgroundColor = "white";
                  }}
                  onDrop={(e) => {
                    props.drop();
                    e.target.style.opacity = "1";
                    e.currentTarget.style.backgroundColor = "white";
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.currentTarget.style.backgroundColor = "lightblue";
                  }}
                  draggable
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {dragIcon}
                    <Typography
                      variant="h6"
                      id={props.page.id}
                      key={props.page.id}
                      style={{
                        marginTop: ".2em",
                        marginLeft: "2em",
                        width: "100%",
                      }}
                    >
                      {props.page.label}
                    </Typography>
                    {props.page.own == 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          props.setCurrentSelected((current) =>
                            current.filter(
                              (element) => element.id != props.page.id
                            )
                          );
                          props.jsonGateway.api.removeAdditionalChapter(
                            props.page.id
                          );
                          document.getElementById(props.page.id).remove();
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    )}
                  </Box>
                </AccordionDetails>
              </>
            )}
          </>
        )}
        {props.isParent &&
          props.own != 1 &&
          props.currentSelected.map((page) => {
            if (page.parent == props.page.id) {
              return (
                <RecursivePages
                  key={page.id}
                  page={page.own != 1 ? data.entities.pages[page.id] : page}
                  data={data}
                  drop={props.drop}
                  dragStart={props.dragStart}
                  dragEnter={props.dragEnter}
                  jsonGateway={jsonGateway}
                  isParent={data.entities.pages[page.id]?.children?.length > 0}
                  parent={data.entities.pages[page.id]?.parent}
                  currentSelected={props.currentSelected}
                />
              );
            }
          })}
      </Accordion>
    );
}
