import React from "react";
import { FormattedMessage } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function BJ_Logo({
  individualisierung = { tag: "BJ_Logo" },
  deckblattIndex,
  activeStep,
}) {
  const { control } = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          <FormattedMessage
            id={`source.application.modules.frontend.views.scripts.katalog.individualisierung.tags.${individualisierung.tag.toLowerCase()}`}
            defaultMessage="Busch-Jaeger Logo"
          />
        </Typography>
      </Grid>
      <Controller
        name={`deckblaetter[${deckblattIndex}].individualisierungen[${activeStep}].value`}
        control={control}
        render={({ field }) => {
          return (
            <FormControlLabel
              control={
                <Checkbox color="primary" {...field} defaultChecked={false} />
              }
              label={
                <FormattedMessage
                  id={`source.application.modules.frontend.views.scripts.katalog.individualisierung.tags.${individualisierung.tag.toLowerCase()}`}
                  defaultMessage="Busch-Jaeger Logo eindrucken"
                />
              }
            />
          );
        }}
      />
    </Grid>
  );
}
