import React, { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { FormattedMessage, useIntl } from "react-intl";
import InfoIcon from "@mui/icons-material/Info";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { BemusterungskatalogContext } from "../../root.component";
import Deckblatt from "../Deckblatt";

export default function Deckblaetter({
  setFinishedSteps,
  updatedTitle,
  setActiveChildStep,
  setOldAddress,
  oldAddress,
}) {
  setFinishedSteps(false);
  const { register, setValue } = useFormContext();
  const {
    mountParcel,
    accessToken,
    isAdm,
    deckblaetter,
    item,
    product,
    productDeckblatt,
    baseUrl,
  } = useContext(BemusterungskatalogContext);
  const { control } = useFormContext();
  const [updatedAddress, setUpdatedAddress] = useState("");
  const [updateAddress, setUpdateAddress] = useState(false);
  const [showCustomersearch, setShowCustomersearch] = useState(false);
  const [updateCustomBack, setUpdateCustomBack] = useState('')

  let indivIndex = 0;
  deckblaetter.map((deckblatt, index) => {
    if (deckblatt.id == productDeckblatt) {
      indivIndex = index;
    }
  });

  useEffect(() => {
    if (isAdm && showCustomersearch == true) {
      const mnt = document.getElementById(
        "single-spa-application:@bjep-parcel/customersearch"
      );
      System.import("@bjep-parcel/customersearch")
        .then((e) => {
          mountParcel(e, {
            domElement: mnt,
            token: accessToken,
            selectionCallback: function (address) {
              setOldAddress({
                address:
                  address.name +
                  address.street +
                  address.zipcode +
                  address.city,
                customerid: address.id,
                title: address.name,
              });
              setUpdatedAddress({
                address: `${address.name} 
${address.street} 
${address.zipcode} ${address.city}`,
                customerid: address.id,
                title: address.name,
                name: address.name,
                street: address.street,
                zipCity: address.zipcode + " " + address.city,
                city: address.city,
              });
              setUpdateAddress(true);
              // let el = document.getElementById("inputFieldx");
              // el.dispatchEvent(new Event("input", { bubbles: true }));
            },
          });
        })
        .then(() => {
          let submitButton = document.querySelector(".form-control");
          submitButton.addEventListener('keypress', (event) => {
            if (e.key === 'Enter') {
              event.preventDefault();
            }
          })
          submitButton.addEventListener("click", (event) => {
            event.preventDefault();
          });
        });
    }
  }, [showCustomersearch]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={6}
        style={{
          display: "flex",
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            fontSize: "20px",
            fontFamily:
              "UniversNextW02-Light, Helvetica Neue, Helvetica, Arial",
          }}
        >
          <InfoIcon /> Individualisieren Sie Ihre Titelseite mit eigenen Daten.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        id="bemusterungskatalogDeckblattSlider"
        style={{
          marginBottom: "3em",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div key={`slides_${deckblaetter[indivIndex].id}`}>
          <img
            className="preview_img img-thumbnail nomargin"
            style={{
              height: "55vh",
              border: "1px solid #ccc",
            }}
            src={
              updatedTitle != ""
                ? updatedTitle
                : updateCustomBack != ""
                  ? baseUrl + "/132/file/" + "8809_preview.jpg"
                  : baseUrl + "/132/file/" + deckblaetter[indivIndex].previewFile.id + ""
            }
            alt={deckblaetter[indivIndex].previewFile.id}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "none",
        }}
      >
        <input type="hidden" defaultValue={item.id} {...register("itemId")} />
      </Grid>
      <Grid item xs={12} id="bemusterungskatalogDeckblattIndividualisierungen">
        <Grid container>
          {deckblaetter.map((deckblatt, index) => {
            return (
              <Deckblatt
                setFinishedSteps={setFinishedSteps}
                key={deckblatt.id}
                deckblatt={deckblatt}
                deckblattIndex={index}
                setUpdateCustomBack={setUpdateCustomBack}
                setActiveChildStep={setActiveChildStep}
                updatedAddress={updatedAddress}
                setUpdatedAddress={setUpdatedAddress}
                showCustomersearch={showCustomersearch}
              />
            );
          })}
        </Grid>
      </Grid>
      {isAdm && (
        <>
          <Grid
            item
            xs={6}
            style={{
              position: "relative",
              top: "-20em",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontSize: "20px",
                fontFamily:
                  "UniversNextW02-Light, Helvetica Neue, Helvetica, Arial",
              }}
            >
              <InfoIcon />
              Möchten Sie die Individualisierung für einen Kunden vornehmen?
            </Typography>
            {/* <Button class="btn btn-dark" onClick={() => setShowCustomersearch(!showCustomersearch)}>Händersuche</Button> */}
            <Button
              variant="contained"
              color="primary"
              style={{ width: "35%" }}
              onClick={() => setShowCustomersearch(!showCustomersearch)}
            >
              <FormattedMessage id="Händlersuche" defaultMessage="Händlersuche" />
            </Button>
          </Grid>
          {showCustomersearch ? (
            <>
              <Grid
                item
                xs={12}
                id="single-spa-application:@bjep-parcel/customersearch"
              ></Grid>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </Grid>
  );
}
