import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import { BemusterungskatalogContext } from "../../../root.component";

const useStyles = makeStyles((theme) => ({
  positionContainer: {
    width: "176px",
    margin: "30px auto",
  },
  position: {
    border: "3px solid #eee",
    display: "inline-block",
    width: "28px",
    height: "28px",
    padding: "1px",
    textAlign: "center",
    margin: "10px",
    boxShadow: "0 0 1px 0px #000",
    position: "relative",
    "&::before": {
      borderTop: "2px dotted #000",
      width: "10px",
      height: "2px",
      right: "-20px",
      top: "10px",
    },
    "&::after": {
      content: "",
      display: "inline-block",
      position: "absolute",
    },
  },
  checked: {
    backgroundColor: "#009FE4",
  },
  radio: {
    opacity: 0,
  },
}));

export default function Titelbild_Position({
  individualisierung = { tag: "Titelbild_Position" },
  deckblattIndex,
  activeStep,
}) {
  const { control } = useFormContext();
  const { positions } = useContext(BemusterungskatalogContext);
  const curPosition = useWatch({
    control,
    name: "position",
  });
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          <FormattedMessage
            id={`source.application.modules.frontend.views.scripts.katalog.individualisierung.upload.position`}
            defaultMessage="Individueller Titel"
          />
        </Typography>
      </Grid>
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12}>
              <RadioGroup {...field}>
                <div className={classes.positionContainer}>
                  {positions.map((position) => {
                    return (
                      <div
                        className={clsx(
                          classes.position,
                          position.value === curPosition && classes.checked
                        )}
                        key={position.value}
                      >
                        <Radio
                          color="primary"
                          value={position.value}
                          key={position.label}
                          className={classes.radio}
                        />
                      </div>
                    );
                  })}
                </div>
              </RadioGroup>
            </Grid>
          );
        }}
        name={`deckblaetter[${deckblattIndex}].individualisierungen[${activeStep}].value`}
        control={control}
      />
    </Grid>
  );
}
