import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useWatch } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import RecursivePages from "./components/RecursivePages";
import Accordion from "@mui/material/Accordion";
import { BemusterungskatalogContext } from "../../root.component";
import Checkbox from "@material-ui/core/Checkbox";

import AccordionSummary from "@mui/material/AccordionSummary";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import Dropzone from "../Dropzone";
import Button from "@material-ui/core/Button";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MuiAlert from "@mui/material/Alert";
import browser from 'regenerator-runtime/runtime'

export default function Order({
  oldCatalogueId,
  setOldCatalogueId,
  setFinishedSteps,
  setCurrentOrder,
  currentOrder
}) {
  setFinishedSteps(true);
  const { jsonGateway, item, product, attachmentDestination } = useContext(
    BemusterungskatalogContext
  );

  let mainElement = 1;
  if (product.id == 5607) {
    mainElement = 889;
  } else if (product.id == 5998) {
    mainElement = 1072;
  }

  const { control } = useFormContext();

  const [currentSelected, setCurrentSelected] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [currentChapter, setCurrentChapter] = useState([]);
  const [orderParent, setOrderParent] = useState(mainElement);
  const [showUpload, setShowUpload] = useState(true);

  // On Drag reorder pages
  // Send new order for own pages
  useEffect(() => {
    console.log(currentOrder)
    var index = 0;
    if (oldCatalogueId != null) {
      setOldCatalogueId(null);
      jsonGateway.api.getAdditionalAttachment(oldCatalogueId, {
        success: (data) => {
          data.map((object) => {
            jsonGateway.api.setAdditionalAttachmentForNewCatalogue(
              item.id,
              object.file_file_id,
              object.label,
            );
            setCurrentSelected((currentSelected) => [
              ...currentSelected,
              {
                id: parseInt(object.file_file_id),
                parent: 1,
                label: object.label,
                own: 1,
                selected: true,
              },
            ]);
            setIndivPdfs([
              ...indivPdfs,
              {
                children: [],
                name: object.label,
                file: object.file_file_id,
                id: object.file_file_id,
                order: object.itemOrder,
              },
            ]);
          });
        },
      });
    }
    jsonGateway.api.getAdditionalAttachment(item.id, {
      success: (data) => {
        data.map((object) => {
          setCurrentSelected((currentOrder) => [
            ...currentOrder,
            {
              id: parseInt(object.file_file_id),
              parent: 1,
              label: object.label,
              own: 1,
              selected: true,
            },
          ]);
          setIndivPdfs([
            ...indivPdfs,
            {
              children: [],
              name: object.label,
              file: object.file_file_id,
              id: object.file_file_id,
            },
          ]);
        });
      },
    });
    for (const [key, value] of Object.entries(pages.entities.pages)) {
      if (value.parent == 1 || value.parent == 889 || value.parent == 1072) {
        setChapters((prev) => [...prev, { index: index, id: value.id }]);
        index++;
      }
      if (value.selected == true) {
        currentSelectedPages.push(value);
      }
    }
    setCurrentSelected(currentSelectedPages);
  }, []);

  let currentSelectedPages = [];
  const [reorder, setReorder] = useState(false);
  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    const index = currentSelected.map((object) => object.id).indexOf(position);
    dragItem.current = index;
  };

  const dragEnter = (e, position) => {
    const index = currentSelected.map((object) => object.id).indexOf(position);
    dragOverItem.current = index;
  };

  //Adjust parent of dragged item
  //reorder list of selected items
  const drop = () => {
    const copyListItems = [...currentSelected];
    const dragItemContent = copyListItems[dragItem.current];
    const dropItemContent = copyListItems[dragOverItem.current];
    if (dragItemContent.own == 1) {
      var chapter = copyListItems[dragOverItem.current].parent;
      setOrderParent(dropItemContent.parent);
      dragItemContent.parent = dropItemContent.parent;
      for (const [key, value] of Object.entries(copyListItems)) {
        if (value.id == chapter) {
          value.children.push(dragItemContent.id);
          setCurrentChapter((prev) => [...prev, value]);
        }
      }
    } else {
      var chapter = copyListItems[dragItem.current].parent;
      setOrderParent(dragItemContent.parent);
      for (const [key, value] of Object.entries(copyListItems)) {
        if (value.id == chapter) {
          setCurrentChapter((prev) => [...prev, value]);
        }
      }
    }
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setReorder(true);
    setCurrentOrder(copyListItems)
    setCurrentSelected(copyListItems);
  };

  //Send data in new order to backend
  useEffect(() => {
    document.body.style.cursor = "wait";
    var order = [];
    var inside = [];
    if (orderParent > 1) {
      currentChapter.map((chapter) => {
        inside = chapter.children;
      });
      for (const [key, value] of Object.entries(currentSelected)) {
        if (inside.includes(value.id)) {
          order.push({
            index: currentSelected.findIndex((item) => item.id == value.id),
            id: value.id,
          });
        }
      }
    } else if (orderParent == 1 || orderParent == 889 || orderParent == 1072) {
      for (const [key, value] of Object.entries(currentSelected)) {
        if (value.parent == 1 || value.parent == 889 || value.parent == 1072) {
          var myId = value.id;
          inside.push(value.id);
          order.push({
            index: chapters.findIndex((chapter) => chapter.id == value.id),
            id: myId,
          });
        }
      }
      chapters.map((chapter) => {
        if (!inside.includes(chapter.id)) {
          var myId = chapter.id;
          order.push({
            index: chapters.findIndex((chapt) => chapt.id == chapter.id),
            id: myId,
          });
        }
      });
    }
    var sendOrder = {};
    order.map((chapter, index) => {
      sendOrder[chapter.id] = index;
    });
    let indexPosition = 0;
    let orderPosition = [];
    currentSelected.map((item) => {
      if (
        item.hasActiveChildren == false ||
        item.own == 1 ||
        item.children.length <= 0
      ) {
        indexPosition++;
        if (item.own == 1) {
          orderPosition.push({ index: indexPosition, id: item.id });
        }
      }
    });
    jsonGateway.api.setAdditionalAttachmentOrder(orderPosition);
    jsonGateway.bapi.order(item.id, orderParent, sendOrder, {
      success: () => {
        document.body.style.cursor = "default";
      },
    });
  }, [currentSelected]);

  const [pdfs, setPdfs] = useState([]);
  const [indivPdfs, setIndivPdfs] = useState([]);
  const [isDropped, setIsDropped] = useState(false);
  const onDrop = useCallback((acceptedFiles) => {
    const sendReq = async () => {
      var xhr = new XMLHttpRequest();
      if (!xhr.upload) {
        return false;
      }
      xhr.open("POST", attachmentDestination, true);
      xhr.setRequestHeader("X-FILENAME", acceptedFiles[0].name);
      xhr.setRequestHeader("X-ATTACHMENTNAME", acceptedFiles[0].name);
      xhr.setRequestHeader("isChapter", true);
      document.body.style.cursor = "wait";

      //Dont know what is happening here
      xhr.onreadystatechange = (e) => {
        if (e.target.readyState == 4 && e.target.status == 200) {
          setPdfs((pdfs) => [
            ...pdfs,
            Object.assign(
              acceptedFiles[0],
              { id: e.target.responseText },
              { preview: URL.createObjectURL(acceptedFiles[0]) }
            ),
            // ...acceptedFiles.map((file) =>
            //   Object.assign(
            //     file,
            //     { id: e.target.responseText },
            //     { preview: URL.createObjectURL(file) }
            //   )
            // ),
          ]);
          setIsDropped(true);
          document.body.style.cursor = "default";
          return e.target.responseText;
        }
      };
      xhr.send(acceptedFiles[0]);
    };
    sendReq();
  }, []);

  const [selectedPages, setSelectedPages] = useState([]);

  const pages = useWatch({
    name: "pages",
  });
  const deckblatt = useWatch({
    name: "deckblaetter",
  });
  const [data, setData] = useState(pages);

  const getSelectedPages = () => {
    Object.keys(data.entities.pages).map((key) => {
      if (data.entities.pages[key].selected) {
        if (
          data.entities.pages[key].parent == 1 ||
          data.entities.pages[key].parent == 889 ||
          data.entities.pages[key].parent == 1072
        ) {
          setSelectedPages((selectedPages) => [
            ...selectedPages,
            data.entities.pages[key],
          ]);
        }
      }
    });
  };

  const indivPage = pdfs.map((file, index) => (
    <Accordion>
      <AccordionSummary
        aria-controls="panel2a-content"
        id={`upload${file.name}`}
      >
        <Grid container
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Grid item xs={12}>
            <h4>Bitte geben Sie Ihrer Seite einen Namen:</h4>
          </Grid>
          <Grid item xs={1}>
            <input
              type="text"
              placeholder="Bezeichnung der Seite"
              id={`${file.name}title`}
              defaultValue={file.name}
              onChange={(e) => {
                pdfs[index].name = e.target.value;
              }}
              style={{
                fontSize: "2em",
              }}
            ></input>
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                let currentName = document.getElementById(
                  file.name + "title"
                ).value;
                setIndivPdfs([
                  ...indivPdfs,
                  {
                    children: [],
                    name: document.getElementById(file.name + "title").value,
                    file: pdfs[index],
                    id: file.id,
                  },
                ]);
                setCurrentSelected((currentSelected) => [
                  ...currentSelected,
                  {
                    id: parseInt(file.id),
                    parent: 1,
                    label: currentName,
                    own: 1,
                    selected: true,
                  },
                ]);
                jsonGateway.api.setAdditionalAttachmentLabel(
                  file.id,
                  document.getElementById(file.name + "title").value
                );
                document.getElementById("upload" + file.name).remove();
              }}
            >
              <CheckBoxIcon />
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
    </Accordion>
  ));

  // clean up
  useEffect(
    () => () => {
      pdfs.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [pdfs]
  );

  const ShowSelectedPages = () => {
    return (
      <>
        {currentSelected.map((page, index) => (
          <>
            {(page.parent == 1 ||
              page.parent == 889 ||
              page.parent == 1072) && (
                <RecursivePages
                  page={
                    page.own == 1
                      ? {
                        selected: true,
                        parent: 1,
                        id: page.id,
                        label: page.label,
                        hasActiveChildren: false,
                        hasElement: true,
                        isActive: true,
                        children: [],
                        own: page.own,
                      }
                      : data.entities.pages[page.id]
                  }
                  data={data}
                  isParent={
                    page.own == 1
                      ? true
                      : data.entities.pages[page.id].children?.length > 0
                  }
                  parent={page.own == 1 ? 1 : data.entities.pages[page.id].parent}
                  currentSelected={currentSelected}
                  setCurrentSelected={setCurrentSelected}
                  index={index}
                  selectedPages={selectedPages}
                  setSelectedPages={setSelectedPages}
                  dragEnter={dragEnter}
                  dragStart={dragStart}
                  drop={drop}
                  jsonGateway={jsonGateway}
                />
              )}
          </>
        ))}
      </>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <InfoIcon /> Legen Sie die Reihenfolge fest, in welcher die Kapitel
          angeordnet sein sollen.
        </Typography>
        <Typography variant="subtitle1">
          Sie können die Kapitel durch drag & drop an die gewünschte Stelle
          verschieben.
        </Typography>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        <Accordion
          id="accordionMain"
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
        >
          <AccordionSummary
            aria-controls="panel2a-content"
            id={data.entities.pages[mainElement].id}
          >
            <Typography variant="h1" gutterBottom>
              Mein Katalog
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            id={`accordiondetails${data.entities.pages[mainElement].label}`}
          >
            <ShowSelectedPages />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}></Grid>
      {showUpload && (
        <>
          <Grid item xs={6}>
            {indivPage}
            <Dropzone
              id="custom_backs"
              name="use_custom_chapter"
              options={{
                onDrop,
                accept: ".pdf",
                dropped: isDropped,
              }}
              tokenDrag="Dateien per drag & drop hier ablegen. Mögliche Dateitypen sind: PDF."
              infoLine="Es können nur PDF Dateien mit einer Maximalgröße von 20MB hochgeladen
              werden."
              tokenDrop="Bitte legen Sie die Dateien hier ab."
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
