import React, { useContext, useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { BemusterungskatalogContext } from "../../../root.component";
import Dropzone from "../../Dropzone";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
    padding: theme.spacing(1),
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Upload({
  individualisierung = { tag: "Upload" },
  deckblattIndex,
  activeStep,
}) {
  const classes = useStyles();
  const { control } = useFormContext();
  const { logos, baseUrl, destination } = useContext(
    BemusterungskatalogContext
  );
  const logo = useWatch({
    control,
    name: "upload",
  });
  const [uploadLogo, setUploadLogo] = useState([]);
  const [uploadedLogo, setUploadedLogo] = useState("null");
  const [isDropped, setIsDropped] = useState(false);
  const [preview, setPreview] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [uploadOtherLogo, setUploadOtherLogo] = useState(false);
  if (logos[2] != undefined && uploadLogo.length == 0) {
    setUploadLogo(logos[2].file);
    setUploadedLogo(logos[2].id);
  }
  const onDrop = useCallback((acceptedFiles) => {
    const sendReq = async () => {
      var xhr = new XMLHttpRequest();
      if (!xhr.upload) {
        return false;
      }
      xhr.open("POST", destination, true);
      xhr.setRequestHeader("X-FILENAME", acceptedFiles[0].name);
      xhr.setRequestHeader("X-ATTACHMENTNAME", acceptedFiles[0].name);

      xhr.upload.addEventListener(
        "progress",
        (e) => {
          var percentageCompleted = parseInt(
            (parseInt(e.loaded) / parseInt(e.total)) * 100
          );
        },
        false
      );

      //Dont know what is happening here
      xhr.onreadystatechange = (e) => {
        if (e.target.readyState == 4 && e.target.status == 200) {
          setUploadLogo(
            acceptedFiles.map((file) =>
              Object.assign(file, { preview: URL.createObjectURL(file) })
            )
          );
          logos[2] = {
            file: acceptedFiles.map((file) =>
              Object.assign(file, { preview: URL.createObjectURL(file) })
            ),
            id: e.target.responseText,
          };
          setPreview(acceptedFiles[0].preview);
          setIsDropped(true);
          setUploadedLogo(e.target.responseText);
          return e.target.responseText;
        } else if (e.target.readyState == 4 && e.target.status == 500) {
          setSnackbarOpen(true);
          setSnackbarText(e.target.responseText);
        }
      };
      xhr.send(acceptedFiles[0]);
    };
    sendReq();
  }, []);

  const thumbs = uploadLogo.map((file) => (
    <div
      key={file.name}
      style={{
        border: "1px solid rgb(204, 204, 204)",
        height: "250px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img src={file.preview} alt={file.name} style={{ height: "100%" }} />
    </div>
  ));

  // clean up
  useEffect(
    () => () => {
      uploadLogo.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [uploadLogo]
  );

  return (
    <Grid container spacing={2}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          setSnackbarOpen(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{snackbarText}</Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          <FormattedMessage
            id={`source.application.modules.frontend.views.scripts.katalog.individualisierung.tags.${individualisierung.tag.toLowerCase()}`}
            defaultMessage="Titelbild"
          />
        </Typography>
      </Grid>
      <Controller
        render={({ field }) => {
          return (
            <RadioGroup {...field} className={classes.fullWidth}>
              <Grid container>
                <Grid item xs={12}>
                  <FormControlLabel
                    value={""}
                    control={<Radio color="primary" />}
                    onClick={() => setUploadOtherLogo(false)}
                    label={
                      <FormattedMessage
                        id="source.application.modules.frontend.views.scripts.katalog.individualisierung.logo.logonotprint"
                        defaultMessage="Standart Titelbild"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    disabled={logos[2] == undefined}
                    value={uploadedLogo}
                    id={uploadLogo}
                    control={<Radio color="primary" />}
                    onClick={(e) => setUploadOtherLogo(true)}
                    label={
                      <FormattedMessage
                        id="source.application.modules.frontend.views.scripts.katalog.individualisierung.logo.logoupload"
                        defaultMessage="Anderes Titelbild verwenden:"
                      />
                    }
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          );
        }}
        name={`deckblaetter[${deckblattIndex}].individualisierungen[${activeStep}].value`}
        control={control}
      />
      <Grid item xs={12} className="thumbnail-holder active-upload">
        <Typography variant="p" gutterBottom>
          Eigenes Titelbild Hochladen
        </Typography>
        <Dropzone
          id="custom_logo"
          options={{
            onDrop,
            accept: ".jpeg,.png,.tiff,.tif,.gif,.jpg",
            dropped: isDropped,
          }}
          tokenDrag="Datei per drag & drop hier ablegen. Mögliche Dateitypen sind: jpeg, gif, tiff, png"
          tokenDrop="Bitte legen Sie die Dateien hier ab."
        />
      </Grid>
      <Grid item xs={12}>
        {thumbs}
      </Grid>
    </Grid>
  );
}
