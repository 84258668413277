import Adressblock from "./Adressblock";
import BJ_Logo from "./Bj_Logo";
import Logo from "./Logo";
import Logo_Bottom from "./Logo_Bottom";
import Quelle from "./Quelle";
import Titel from "./Titel";
import Titelbild_Position from "./Titelbild_Position";
import Titelbild_Skalierung from "./Titelbild_Skalierung";
import Untertitel from "./Untertitel";
import Upload from "./Upload";
import CustomBack from "./CustomBack";

export default {
  Adressblock,
  BJ_Logo,
  Logo,
  Logo_Bottom,
  Quelle,
  Titel,
  Titelbild_Position,
  Titelbild_Skalierung,
  Untertitel,
  Upload,
  CustomBack,
};
