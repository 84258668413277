import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import React, { useState, useMemo, useEffect, useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Box from "@mui/material/Box";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";

export default function RecursiveChapter(props) {
  let data = props.data;
  // const selected = data.entities.pages[props.page.id].selected;
  let topic = false;
  if (
    data.entities.pages[props.page.id].parent == 1 ||
    data.entities.pages[props.page.id].parent == 889
  ) {
    topic = true;
  }
  // const topic = data.entities.pages[props.page.id].parent == 1;
  const hasParent = data.entities.pages[props.page.id].parent;
  const hasChildren = data.entities.pages[props.page.id].children?.length > 0;
  let parentContainer;
  if (hasParent) {
    parentContainer = document.getElementById(hasParent);
    if (
      parentContainer &&
      (parentContainer.id != "1" || parentContainer != "889")
    ) {
      const input = document.createElement("p");
      const text = document.createTextNode(
        data.entities.pages[props.page.id].label
      );
      parentContainer.appendChild(input);
      input.appendChild(text);
    }
  }
  return (
    <>
      {props.Chapters == "Chapter" ? (
        <Accordion
          id="accordionRecursive"
          expanded={true}
          key={`accordionRecursive${props.page.id}`}
        >
          {topic && (
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              key={`accordionSummary${props.page.id}`}
              style={{ cursor: "default" }}
              onClick={() => {
                props.setCurrentElement(props.page.id);
              }}
            >
              {/* <Typography variant="h5" gutterBottom> */}
              <h5 style={{ marginLeft: "1em" }}>
                {data.entities.pages[props.page.id].label}
              </h5>
              {/* </Typography> */}
            </AccordionSummary>
          )}
        </Accordion>
      ) : (
        <Accordion
          id={`accordions${props.page.id}`}
          key={`accordionTopic${props.page.id}`}
          defaultExpanded={
            props.currentElement == props.page.id ||
            props.parentElements.includes(props.page.id)
          }
        >
          {topic ? (
            <>
              {hasChildren ? (
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  // id="panel2a-header"
                  key={`accordionChildSummary${props.page.id}`}
                  id={`accordion${props.page.id}`}
                  onClick={() => {
                    props.setCurrentElement(props.page.id);
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {props.page.id == props.currentElement ? (
                        <>
                          <Checkbox
                            defaultChecked={props.page.selected == true}
                            disabled={props.wait}
                            id={props.page.id}
                            onClick={(e) => {
                              props.handleSelectClick(e);
                              e.stopPropagation();
                            }}
                          />
                          <h2 style={{ marginLeft: ".5em", color: "#009ee0" }}>
                            {data.entities.pages[props.page.id].label}
                          </h2>
                        </>
                      ) : (
                        <>
                          <Checkbox
                            defaultChecked={props.page.selected == true}
                            id={props.page.id}
                            disabled={props.wait}
                            onClick={(e) => {
                              props.handleSelectClick(e);
                              e.stopPropagation();
                            }}
                          />
                          <h2 style={{ marginLeft: ".5em" }}>
                            {data.entities.pages[props.page.id].label}
                          </h2>
                        </>
                      )}
                    </Box>
                  </Box>
                </AccordionSummary>
              ) : (
                <AccordionDetails
                  // expandIcon={<ImageSearchIcon />}
                  id={`accordion${props.page.id}`}
                  key={`accordionChildDetails${props.page.id}`}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {props.page.id == props.currentElement ? (
                        <>
                          <Checkbox
                            defaultChecked={props.page.selected == true}
                            id={props.page.id}
                            disabled={props.wait}
                            onClick={(e) => {
                              props.handleSelectClick(e);
                              e.stopPropagation();
                            }}
                          />
                          <h2 style={{ marginLeft: "1.5em", color: "#009ee0" }}>
                            {data.entities.pages[props.page.id].label}
                          </h2>
                        </>
                      ) : (
                        <>
                          <Checkbox
                            defaultChecked={props.page.selected == true}
                            id={props.page.id}
                            disabled={props.wait}
                            onClick={(e) => {
                              props.handleSelectClick(e);
                              e.stopPropagation();
                            }}
                          />
                          <h5 style={{ marginLeft: "2em" }}>
                            {data.entities.pages[props.page.id].label}
                          </h5>
                        </>
                      )}
                    </Box>
                    {/* <Typography
                        variant="h6"
                        gutterBottom
                        id={props.page.id}
                        key={props.page.id}
                      >
                        {data.entities.pages[props.page.id].label}
                      </Typography> */}
                    <ImageSearchIcon
                      fontSize="large"
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                </AccordionDetails>
              )}
            </>
          ) : (
            <>
              {hasChildren ? (
                <>
                  <AccordionSummary
                    // defaultExpanded={props.currentElement == props.page.id || props.page.parent == props.currentElement}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id={`accordion${props.page.id}`}
                    key={`acordionChildSummary2${props.page.id}`}
                    onClick={() => {
                      props.setCurrentElement(props.page.id);
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {props.page.id == props.currentElement ? (
                        <>
                          <Checkbox
                            defaultChecked={props.page.selected == true}
                            id={props.page.id}
                            disabled={props.wait}
                            onClick={(e) => {
                              props.handleSelectClick(e);
                              e.stopPropagation();
                            }}
                          />
                          <h2 style={{ marginLeft: "1em", color: "#009ee0" }}>
                            {data.entities.pages[props.page.id].label}
                          </h2>
                        </>
                      ) : (
                        <>
                          <Checkbox
                            defaultChecked={props.page.selected == true}
                            id={props.page.id}
                            disabled={props.wait}
                            onClick={(e) => {
                              props.handleSelectClick(e);
                              e.stopPropagation();
                            }}
                          />
                          <h5 style={{ marginLeft: "1.5em" }}>
                            {data.entities.pages[props.page.id].label}
                          </h5>
                        </>
                      )}
                    </Box>
                  </AccordionSummary>
                </>
              ) : (
                <>
                  <AccordionDetails
                    // expandIcon={<ImageSearchIcon />}
                    id={`accordion${props.page.id}`}
                    key={`acordionChildDetails2${props.page.id}`}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {props.page.id == props.currentElement ? (
                          <>
                            <Checkbox
                              defaultChecked={props.page.selected == true}
                              id={props.page.id}
                              disabled={props.wait}
                              onClick={(e) => {
                                props.handleSelectClick(e);
                                e.stopPropagation();
                              }}
                            />
                            <h1
                              style={{ marginLeft: "1.5em", color: "#009ee0" }}
                            >
                              {data.entities.pages[props.page.id].label}
                            </h1>
                          </>
                        ) : (
                          <>
                            <Checkbox
                              defaultChecked={props.page.selected == true}
                              id={props.page.id}
                              disabled={props.wait}
                              onClick={(e) => {
                                props.handleSelectClick(e);
                                e.stopPropagation();
                              }}
                            />
                            <h5 style={{ marginLeft: "2.5em" }}>
                              {data.entities.pages[props.page.id].label}
                            </h5>
                          </>
                        )}
                      </Box>
                      <ImageSearchIcon
                        fontSize="large"
                        onClick={() => {
                          if (props.page.element != null) {
                            props.handlePreviewOpen(props.page.element);
                          } else {
                            props.handlePreviewOpen(1369);
                          }
                        }}
                      />
                    </Box>
                  </AccordionDetails>
                </>
              )}
            </>
          )}
          {hasChildren &&
            data.entities.pages[props.page.id].children.map((childId) => {
              if (data.entities.pages[childId] != undefined) {
                return (
                  <RecursiveChapter
                    key={childId}
                    page={data.entities.pages[childId]}
                    data={data}
                    wait={props.wait}
                    handleSelectClick={props.handleSelectClick}
                    setCurrentElement={props.setCurrentElement}
                    handlePreviewOpen={props.handlePreviewOpen}
                    currentElement={props.currentElement}
                    parentPages={props.parentPages}
                    parentElements={props.parentElements}
                  />
                );
              }
            })}
        </Accordion>
      )}
    </>
  );
}
