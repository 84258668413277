import React, { useState, useMemo, useEffect, useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Card from "../Pages/components/Card";
import RecursiveChapter from "../Pages/components/RecursiveChapter";
import ModalPreview from "../Pages/components/ModalPreview";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { BemusterungskatalogContext } from "../../root.component";
import InfoIcon from "@mui/icons-material/Info";

export default function Subpages(props) {
  props.setFinishedSteps(true);
  const [currentPage, setCurrentPage] = useState(0);
  const theme = useTheme();
  const { setValue } = useFormContext();
  const { jsonGateway, item, product, maxSeiten, baseUrl } = useContext(
    BemusterungskatalogContext
  );
  const pages = useWatch({
    name: "pages",
  });
  const deckblatt = useWatch({
    name: "deckblaetter",
  });
  // const catalogueName = deckblatt[0].individualisierungen[3].value;

  const [data, setData] = useState(pages);
  const [wait, setWait] = useState(false);

  let mainElement = 1;
  if (product.id == 5607) {
    mainElement = 889;
  } else if (product.id == 5998) {
    mainElement = 1072;
  }
  const [selectedPages, setSelectedPages] = useState([]);

  //Preview Images
  const [openPreview, setOpenPreview] = useState(false);
  const [file, setFile] = useState(null);
  const handlePreviewOpen = (page) => {
    setFile("/assets-katalogpflege/img/element/" + page + "/100-750-750.jpg");
    setOpenPreview(true);
  };
  const handlePreviewClose = () => setOpenPreview(false);

  //Add Catalogue Pages
  const addPages = (element) => {
    document.body.style.cursor = "wait";
    setWait(true);
    jsonGateway.bapi.change(item.id, element.id, "checked", true, {
      success: () => {
        setWait(false);
        document.body.style.cursor = "default";
      },
    });
    const selectChilds = (id) => {
      const element = data.entities.pages[id];
      if (element != undefined) {
        element.selected = true;
        if (element?.children?.length > 0) {
          element.children.map((childId) => {
            if (data.entities.pages[childId] != undefined) {
              data.entities.pages[childId].selected = true;
            }
            selectChilds(childId);
          });
        }
      }
    };
    if (data.entities.pages[element.parent] != undefined) {
      data.entities.pages[element.parent].selected = true;
    }
    if (
      (element.parent != 1 ||
        element.parent != 889 ||
        element.parent != 1072) &&
      data.entities.pages[element.parent].parent != undefined
    ) {
      setSelectedPages((pages) => [
        ...pages,
        {
          parent: data.entities.pages[element.parent].parent,
          children: data.entities.pages[element.parent].children,
          hasActiveChildren: true,
          label: data.entities.pages[element.parent].label,
        },
      ]);
    }
    if (element.children?.length > 0) {
      selectChilds(element.id);
      setSelectedPages((pages) => [
        ...pages,
        {
          parent: element.id,
          children: element.children,
          hasActiveChildren: element.children?.length > 0,
          label: element.label,
        },
      ]);
    } else {
      setSelectedPages((selectedPages) => [
        ...selectedPages,
        { parent: element.id, children: [] },
      ]);
    }
  };

  //Remove Catalogue Pages
  const removePages = (element) => {
    document.body.style.cursor = "wait";
    setWait(true);
    jsonGateway.bapi.change(item.id, element.id, "checked", false, {
      success: () => {
        setWait(false);
        document.body.style.cursor = "default";
      },
    });
    const selectChilds = (id) => {
      const element = data.entities.pages[id];
      if (element != undefined) {
        element.selected = false;
        if (element?.children?.length > 0) {
          element.children.map((childId) => {
            if (data.entities.pages[childId] != undefined) {
              data.entities.pages[childId].selected = false;
            }
            selectChilds(childId);
          });
        }
      }
    };
    selectChilds(element.id);
    if (data.entities.pages[element.id] != undefined) {
      data.entities.pages[element.id].selected = false;
    }
    let selectedPagesWithDelete = selectedPages;
    setSelectedPages(selectedPagesWithDelete);
  };

  const getSelectedPages = (id, element) => {
    document.body.style.cursor = "wait";
    if (element != undefined) {
      element?.selected ? removePages(element) : addPages(element);
    }
  };

  let pageCount = 0;
  let parentPages = [];
  for (const [key, value] of Object.entries(data.entities.pages)) {
    if (value != undefined) {
      if (value.selected == true) {
        pageCount = pageCount + 1;
        if (value.parent == 1 || value.parent == 889 || value.parent == 1072) {
          parentPages.push(value.id);
        }
      }
    }
  }

  useEffect(() => {
    setData(pages);
  }, [pages, setData]);

  const [currentElement, setCurrentElement] = useState(
    parentPages[currentPage]
  );

  const parentElements = useMemo(() => {
    let order = [];
    if (data?.entities?.pages) {
      if (currentElement) {
        order.push(currentElement);
        let element = data.entities.pages[currentElement];
        while (element?.parent) {
          order.push(element.parent);
          element = data.entities.pages[element.parent];
        }
      }
    }
    return order.reverse();
  }, [data, currentElement]);

  const getIds = (ids = [], id) => {
    if (data?.entities?.pages) {
      let element = data.entities.pages[id];
      ids.push(id);
      if (element?.children?.length > 0) {
        element.children.map((childId) => {
          ids = getIds(ids, childId);
        });
      }
    }

    return ids;
  };

  const getCompleteSelected = (id) => {
    const ids = getIds([], id);
    let selected = true;
    ids.forEach((subId) => {
      if (data.entities.pages[subId] != undefined) {
        if (!data?.entities?.pages[subId].selected) {
          selected = false;
        }
      }
    });
    return selected;
  };

  if (!data || !currentElement) {
    return null;
  }

  if (currentPage == parentPages.length - 1) {
    props.setFinishedSteps(true);
  }

  const [selectedOnLoad, setSelectedOnLoad] = useState([]);
  useEffect(() => {
    // let selectedOnLoad = [];
    for (const [key, value] of Object.entries(pages.entities.pages)) {
      if (value != undefined) {
        if (
          value?.selected == true &&
          (value.parent == 1 || value.parent == 889 || value.parent == 1072)
        ) {
          removePages(value)
          setSelectedOnLoad((prev) => [...prev, value]);
        }
      }
    }
  }, []);

  const ShowSelectedPages = () => {
    const handleSelectClick = (e) => {
      if (e.target.checked == false) {
        if (wait == false) {
          const id = e.target.id;
          const element = data.entities.pages[id];
          const completeSelected = getCompleteSelected(id);
          if (data.entities.pages[id] != undefined) {
            if (data.entities.pages[id].selected != true) {
              return null;
            }
          }
          e.stopPropagation();
          const value = completeSelected ? false : true;
          let newData = JSON.parse(JSON.stringify(data));
          let ids = getIds([], id);

          if (completeSelected) {
            ids.forEach((subId) => {
              if (newData.entities.pages[subId] != undefined) {
                newData.entities.pages[subId].selected = false;
              }
            });
          } else {
            ids.forEach((subId) => {
              if (newData.entities.pages[subId] != undefined) {
                newData.entities.pages[subId].selected = value;
              }
            });
          }
          setValue("pages", newData);
          getSelectedPages(id, element);
        }
      } else {
        const id = e.target.id;
        let myParent = data.entities.pages[id].parent
        while (myParent != mainElement) {
          data.entities.pages[myParent].selected = true;
          myParent = data.entities.pages[myParent].parent
        }
        const element = data.entities.pages[id];
        const completeSelected = getCompleteSelected(id);
        if (data.entities.pages[id] != undefined) {
          if (data.entities.pages[id].selected == true) {
            return null;
          }
        }
        e.preventDefault();
        e.stopPropagation();
        const value = completeSelected ? false : true;
        let newData = JSON.parse(JSON.stringify(data));
        let ids = getIds([], id);

        if (completeSelected) {
          ids.forEach((subId) => {
            if (newData.entities.pages[subId] != undefined) {
              newData.entities.pages[subId].selected = true;
            }
          });
        } else {
          ids.forEach((subId) => {
            if (newData.entities.pages[subId] != undefined) {
              newData.entities.pages[subId].selected = value;
            }
          });
        }
        setValue("pages", newData);
        getSelectedPages(id, element);
      }
    };
    return (
      <>
        {selectedOnLoad.map((page) => (
          <>
            {data.entities.pages[page.id].own != 1 && (
              <RecursiveChapter
                page={data.entities.pages[page.id]}
                data={pages}
                wait={wait}
                handlePreviewOpen={handlePreviewOpen}
                currentElement={currentElement}
                parentPages={parentPages}
                handleSelectClick={handleSelectClick}
                currentPage={currentPage}
                setCurrentElement={setCurrentElement}
                parentElements={parentElements}
              />
            )}
          </>
        ))}
      </>
    );
  };

  const ShowPageCount = () => {
    return (
      <Grid
        item
        xs={12}
        style={{ padding: "1em", background: "rgb(230, 230, 230)" }}
      >
        <Typography variant="h5">
          <b>{pageCount}</b> Seiten ausgewählt
        </Typography>
        {pageCount > 0 && (
          <Typography variant="h5">
            Erhältlich als:{" "}
            {pageCount < 36
              ? "Softcover-Bindung"
              : pageCount < 240
                ? "Hardcover-Bindung"
                : "Download"}
          </Typography>
        )}
      </Grid>
    );
  };
  return (
    <Grid container spacing={2}>
      <ModalPreview
        mainElement={mainElement}
        file={file}
        openPreview={openPreview}
        handlePreviewClose={handlePreviewClose}
        handlePreviewOpen={handlePreviewOpen}
      />
      <Grid item xs={12} style={{ display: "flex" }}>
        <Typography
          variant="subtitle1"
          style={{
            fontSize: "20px",
            fontFamily:
              "UniversNextW02-Light, Helvetica Neue, Helvetica, Arial",
          }}
        >
          <InfoIcon /> Wählen Sie alle Seiten aus, welche im Kapitel{" "}
          <b>{data.entities.pages[parentPages[currentPage]]?.label}</b>{" "}
          enthalten sein sollen.
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Accordion id="accordionMain" expanded={true}>
          <Typography variant="h4" gutterBottom style={{ margin: ".5em" }}>
            Mein Katalog
          </Typography>
          <AccordionDetails
            id={`accordiondetails${data.entities.pages[mainElement].label}`}
          >
            <ShowSelectedPages />
          </AccordionDetails>
          <ShowPageCount />
        </Accordion>
      </Grid>

      <Grid item xs={1}>
        <div
          style={{
            height: "100%",
            width: "5%",
            background: "rgb(0, 118, 183, 0.2)",
            margin: "auto",
          }}
        ></div>
      </Grid>

      <Grid item xs={7}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            {parentElements.map((id) => {
              if (id != 1 || id != 889) {
                const element = data.entities.pages[id];
                const handleClick = () => {
                  setCurrentElement(id);
                };
                return (
                  <Button key={element.id} onClick={handleClick}>
                    {element?.label}
                  </Button>
                );
              }
            })}
          </Breadcrumbs>
        </Grid>
        <Grid container spacing={2}>
          {data.entities.pages[currentElement]?.children.map((id, index) => {
            const element = data.entities.pages[id];
            const completeSelected = getCompleteSelected(id);
            if (element != undefined) {
              if (element.selected != true) {
                return null;
              }
            }
            const handleExpandClick = (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (element?.hasActiveChildren) {
                setCurrentElement(id);
              }
            };

            const handleSelectClick = (e) => {
              if (wait == false) {
                e.stopPropagation();
                const value = completeSelected ? false : true;
                let newData = JSON.parse(JSON.stringify(data));
                let ids = getIds([], id);

                if (completeSelected) {
                  ids.forEach((subId) => {
                    if (newData.entities.pages[subId] != undefined) {
                      newData.entities.pages[subId].selected = false;
                    }
                  });
                } else {
                  ids.forEach((subId) => {
                    if (newData.entities.pages[subId] != undefined) {
                      newData.entities.pages[subId].selected = value;
                    }
                  });
                }
                setValue("pages", newData);
                getSelectedPages(id, element);
              }
            };

            return (
              <Card
                key={id}
                element={element}
                image={
                  element != undefined
                    ? element.element != null
                      ? baseUrl +
                      "/assets-katalogpflege/img/element/" +
                      element.element +
                      "/100-750-750.jpg"
                      : "/132/file/1059"
                    : "/132/file/1059"
                }
                mainElement={mainElement}
                index={index}
                selected={element?.selected}
                completeSelected={completeSelected}
                handleSelectClick={handleSelectClick}
                handleExpandClick={handleExpandClick}
                displayName="Subpages"
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
