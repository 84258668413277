import React, { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CachedIcon from "@material-ui/icons/Cached";

import { BemusterungskatalogContext } from "../../root.component";
import Individualisierungen from "../Individidualisierungen";

export default function Deckblatt({
  deckblatt,
  deckblattIndex,
  setActiveChildStep,
  setFinishedSteps,
  updatedAddress,
  setUpdatedAddress,
  showCustomersearch,
  setUpdateCustomBack
}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [preview, setPreview] = useState()

  const {
    baseUrl,
    productDeckblatt,
  } = useContext(BemusterungskatalogContext);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setActiveChildStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setUpdateCustomBack('')
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setActiveChildStep((prevActiveStep) => prevActiveStep - 1);
  };

  const Component =
    Individualisierungen[deckblatt.individualisierungen[activeStep].tag];
  return (
    <Grid
      item
      xs={12}
      id={`individualisierungItem${deckblatt.id}`}
      style={{
        display: deckblatt.id !== productDeckblatt ? "none" : "initial",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center" style={{ display: "none" }}>
              <img
                src={`${baseUrl}/132/file/${deckblatt.previewFile.id}`}
                alt={deckblatt.previewFile.id}
              />
            </Grid>
            <Grid item xs={12} align="center">
              <Button
                id="renderTitelseite"
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<CachedIcon />}
              >
                <FormattedMessage
                  id="source.application.modules.frontend.views.scripts.cart.detail.bemusterungskatalog.rendertitle"
                  defaultMessage="Titelseite aktualisieren"
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            {Component && (
              <Grid item xs={12} style={{ minHeight: "19em" }}>
                <Component
                  style={{ height: "19em" }}
                  setPreview={setPreview}
                  preview={preview}
                  activeStep={activeStep}
                  deckblattIndex={deckblattIndex}
                  setUpdateCustomBack={setUpdateCustomBack}
                  individualisierung={
                    deckblatt.individualisierungen[activeStep]
                  }
                  updatedAddress={updatedAddress}
                  setUpdatedAddress={setUpdatedAddress}
                  showCustomersearch={showCustomersearch}
                />
              </Grid>
            )}
            {deckblatt.individualisierungen.length > 0 && (
              <Grid item xs={12}>
                <MobileStepper
                  variant="progress"
                  steps={deckblatt.individualisierungen.length}
                  position="static"
                  activeStep={activeStep}
                  nextButton={
                    <Button
                      id="individualisationForwardButton"
                      size="small"
                      onClick={handleNext}
                      disabled={
                        activeStep >= deckblatt.individualisierungen.length - 1
                      }
                    >
                      weiter
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      zurück
                    </Button>
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
