import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { BemusterungskatalogContext } from "../../root.component";
import { Button } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@material-ui/core/TextField";

export default function OldCatalogue({
  showOldCatalogues,
  setRoot,
  setOldAddress,
  setStartIndividualisation,
  setUseOldCatalogue,
  setOldCatalogueId,
  oldAddress,
}) {
  const { deckblaetter, jsonGateway, product, oldCatalogues } = useContext(
    BemusterungskatalogContext
  );

  const { control } = useFormContext();
  const [wait, setWait] = useState(null);

  const copyBmk = (catalogue) => {
    loadSpinner(null, "Der ausgewählte Katalog wird geladen");
    setWait(true);
    jsonGateway.api.copyBemusterungskatalog(catalogue, {
      success: (data) => {
        setRoot(JSON.parse(data[0]));
        setOldAddress({
          address: `${data[1][2].value}`,
          title: data[1][3].value,
          image: data[1][6].value,
          bjLogo: data[1][5].value,
          subtitle: data[1][4].value,
          source: data[1][8].value,
          logo: data[1][0].value,
          logoBottom: data[1][1].value,
          scale: data[1][7].value,
        });
        setOldCatalogueId(catalogue);
        let el2 = document.getElementById("titleInput");
        el2.dispatchEvent(new Event("input", { bubbles: true }));
        let el = document.getElementById("inputField");
        el.dispatchEvent(new Event("input", { bubbles: true }));
        let el3 = document.getElementById("inputField3");
        el3.dispatchEvent(new Event("input", { bubbles: true }));
        let el4 = document.getElementById("inputField4");
        el4.dispatchEvent(new Event("input", { bubbles: true }));
        let el5 = document.getElementById("inputField5");
        el5.dispatchEvent(new Event("input", { bubbles: true }));
        let el6 = document.getElementById("inputField6");
        el6.dispatchEvent(new Event("input", { bubbles: true }));
        let el7 = document.getElementById("inputField7");
        el7.dispatchEvent(new Event("input", { bubbles: true }));
        let el8 = document.getElementById("inputField8");
        el8.dispatchEvent(new Event("input", { bubbles: true }));
        let el9 = document.getElementById("inputField9");
        el9.dispatchEvent(new Event("input", { bubbles: true }));
        setWait(false);
      },
    });
  };

  useEffect(() => {
    if (wait == false) {
      setUseOldCatalogue(true);
      setStartIndividualisation(true);
      deactivateSpinner();
    }
  }, [wait]);

  return (
    <>
      <Grid container xs={12} spacing={2}>
        {showOldCatalogues == true && (
          <>
            <Grid item xs={12}>
              <h1>Wählen Sie einen Ihrer bereits erstellten Kataloge aus:</h1>
            </Grid>
            {oldCatalogues.length > 0 ? (
              <>
                {oldCatalogues.map((catalogue) => (
                  catalogue.title != null && (
                    <Grid
                      item
                      xs={12}
                      md={2}
                      spacing={2}
                      key={catalogue.cart}
                      style={{
                        border: "2px solid rgba(0, 109, 167, 0.3)",
                        margin: "2em",
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          height: "25vh",
                        }}
                        onClick={() => {
                          copyBmk(catalogue.cart);
                        }}
                      >
                        <Grid item xs={12}>
                          <h1 style={{ color: "black", hyphens: "auto" }}>
                            {catalogue.title}
                          </h1>
                          <p style={{
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 3,
                          }}>{catalogue.address}</p>
                          <span>{catalogue.timestamp}</span>
                        </Grid>
                      </Button>
                    </Grid>
                  )
                ))}
              </>
            ) : (
              <Grid
                item
                xs={12}
                md={3}
                spacing={2}
                key={0}
                style={{ background: "#e0e0e0", margin: "2em" }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    height: "30vh",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <h1>Sie haben bisher keinen Katalog individualisiert</h1>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12} hidden={true}>
              <TextField
                onInput={(e) => field.onChange(e.target.value)}
                id="inputField"
                {...field}
                value={` ${oldAddress.address} `}
              />
            </Grid>
          );
        }}
        name={`deckblaetter[` + product.index + `].individualisierungen[2].value`}
        control={control}
      />
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12} hidden={true}>
              <TextField
                onInput={(e) => field.onChange(e.target.value)}
                id="titleInput"
                {...field}
                value={oldAddress.title}
              />
            </Grid>
          );
        }}
        name={`deckblaetter[` + product.index + `].individualisierungen[3].value`}
        control={control}
      />
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12} hidden={true}>
              <TextField
                onInput={(e) => field.onChange(e.target.value)}
                id="inputField3"
                {...field}
                value={oldAddress.image}
              />
            </Grid>
          );
        }}
        name={`deckblaetter[` + product.index + `].individualisierungen[6].value`}
        control={control}
      />
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12} hidden={true}>
              <TextField
                onInput={(e) => field.onChange(e.target.value)}
                id="inputField4"
                {...field}
                value={oldAddress.bjLogo}
              />
            </Grid>
          );
        }}
        name={`deckblaetter[` + product.index + `].individualisierungen[5].value`}
        control={control}
      />
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12} hidden={true}>
              <TextField
                onInput={(e) => field.onChange(e.target.value)}
                id="inputField5"
                {...field}
                value={oldAddress.subtitle}
              />
            </Grid>
          );
        }}
        name={`deckblaetter[` + product.index + `].individualisierungen[4].value`}
        control={control}
      />
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12} hidden={true}>
              <TextField
                onInput={(e) => field.onChange(e.target.value)}
                id="inputField6"
                {...field}
                value={oldAddress.scale}
              />
            </Grid>
          );
        }}
        name={`deckblaetter[` + product.index + `].individualisierungen[7].value`}
        control={control}
      />
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12} hidden={true}>
              <TextField
                onInput={(e) => field.onChange(e.target.value)}
                id="inputField7"
                {...field}
                value={oldAddress.logo}
              />
            </Grid>
          );
        }}
        name={`deckblaetter[` + product.index + `].individualisierungen[0].value`}
        control={control}
      />
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12} hidden={true}>
              <TextField
                onInput={(e) => field.onChange(e.target.value)}
                id="inputField8"
                {...field}
                value={oldAddress.logoBottom}
              />
            </Grid>
          );
        }}
        name={`deckblaetter[` + product.index + `].individualisierungen[1].value`}
        control={control}
      />
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12} hidden={true}>
              <TextField
                onInput={(e) => field.onChange(e.target.value)}
                id="inputField9"
                {...field}
                value={oldAddress.source}
              />
            </Grid>
          );
        }}
        name={`deckblaetter[` + product.index + `].individualisierungen[8].value`}
        control={control}
      />
    </>
  );
}
