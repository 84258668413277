import React, { useRef } from "react";
// import { useDrag, useDrop } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check";
import RemoveIcon from "@material-ui/icons/Remove";
import Tooltip from "@material-ui/core/Tooltip";

export default function Card({
  element,
  mainElement,
  image,
  selected,
  completeSelected,
  handleSelectClick,
  chapter,
  displayName,
  wait,
}) {
  const useStyles = makeStyles(() => ({
    fullHeight: {
      lineBreak: "anywhere",
    },
    midHeight: {
      position: "absolute",
      top: "0",
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "end",
    },
  }));
  const classes = useStyles();

  //Show catalogue page preview
  if (image != null) {
  } else {
  }
  const itemType = "CARD";
  const ref = useRef(null);

  return (
    <>
      {element == undefined ? (
        <></>
      ) : (
        <Grid item xs={6} sm={4} md={3} onClick={handleSelectClick} ref={ref}>
          <div
            style={{
              background:
                chapter == true
                  ? wait == true
                    ? "#eeeeee"
                    : ""
                  : `url(` + image + `)`,
              backgroundSize: "cover",
              cursor: "pointer",
              color: "black",
              width:
                chapter == true
                  ? "100%"
                  : mainElement == 1072
                  ? "195px"
                  : "165.3px",
              height:
                chapter == true
                  ? "100%"
                  : mainElement == 1072
                  ? "137.9px"
                  : "234px",
              minHeight: "100px",
              position: "relative",
              border: `2px solid ${
                selected ? "rgb(0, 118, 183, 0.85)" : "rgb(0, 109, 167, 0.3)"
              }`,
            }}
          >
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{
                position: "absolute",
                inset: 8,
                width: "100%",
              }}
            >
              {chapter && (
                <Grid
                  item
                  xs={12}
                  align="center"
                  className={
                    element?.parent == 1 || element?.parent == 889
                      ? classes.fullHeight
                      : classes.midHeight
                  }
                >
                  {element?.label}
                </Grid>
              )}
              {element?.hasActiveChildren && displayName == "Subpages" && (
                <Grid
                  item
                  xs={12}
                  style={{
                    height: "35%",
                    color: "white",
                    position: "absolute",
                    bottom: "0",
                    width: "100%",
                  }}
                ></Grid>
              )}
              {selected && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                >
                  <div
                    style={{
                      width: 50,
                      height: 50,
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: 0,
                        height: 0,
                        WebkitTransform: "rotate(360deg)",
                        borderStyle: "solid",
                        borderWidth: "0 0 40px 40px",
                        borderColor:
                          "transparent transparent transparent rgb(0, 118, 183, 0.85)",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "2%",
                        left: "2%",
                      }}
                    >
                      {completeSelected && (
                        <CheckIcon
                          fontSize="medium"
                          style={{ color: "white" }}
                        />
                      )}
                      {!completeSelected && <RemoveIcon />}
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </div>
          {!chapter && <p style={{ width: "165.3px" }}>{element?.label}</p>}
        </Grid>
      )}
    </>
  );
}
