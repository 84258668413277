import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState, useMemo, useEffect, useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

export default function ModalPreview({
  mainElement,
  file,
  openPreview,
  handlePreviewClose,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: mainElement == 1072 ? 702 : 496,
    height: mainElement == 1072 ? 496 : 702,
    bgcolor: "background.paper",
    border: "2px solid #000",
    backgroundSize: "cover",
    backgroundImage: "url(" + file + ")",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={openPreview}
      onClose={handlePreviewClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}></Box>
    </Modal>
  );
}
