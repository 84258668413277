import React, { createContext, useMemo, useState, useRef } from "react";
import { IntlProvider } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { normalize, schema } from "normalizr";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import { Helmet } from "react-helmet";

import CustomBack from "./components/CustomBack";
import Load from "./components/Load";
import Deckblaetter from "./components/Deckblaetter";
import Pages from "./components/Pages";
import Order from "./components/Order";
import Subpages from "./components/Subpages";
import OldCatalogue from "./components/OldCatalogue";

import defaultData from "./defaultData.json";

export const BemusterungskatalogContext = createContext({});

const defaultTheme = createTheme({
  typography: {
    fontFamily: [
      "UniversNextW02-Light",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: 22,
    h1: {
      fontSize: 28,
    },
    h2: {
      fontSize: 24,
    },
    h3: {
      fontSize: 22,
    },
    h4: {
      fontSize: 20,
    },
    caption: {
      fontSize: 18,
    },
    subtitle1: {
      fontSize: 20,
    },
  },
  palette: {
    primary: {
      main: "#005584",
      dark: "#2F4863",
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: 14,
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
  },
});

const useStyles = makeStyles({
  underline: {
    borderBottom: "1px solid #c2c2c2",
  },
});

export default function Root({
  mountParcel,
  accessToken,
  domElement,
  oldCatalogues = "",
  title = defaultData.title,
  action = defaultData.action,
  deckblaetter = defaultData.deckblaetter,
  baseUrl = "",
  productDeckblatt = defaultData.productDeckblatt,
  isAdm = true,
  admUrl = "",
  alteKataloge = [],
  item = { id: null },
  product = { id: null, index: null },
  logos = [],
  destination = "",
  attachmentDestination = "",
  messages = defaultData.messages,
  activeHaendler = defaultData.activeHaendler,
  positions = defaultData.positions,
  root = defaultData.root,
  jsonGateway = { api: () => null },
  url = "",
  theme = defaultTheme,
  maxSeiten = defaultData.maxSeiten,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [activeChildStep, setActiveChildStep] = useState(0);
  const [totalIndivSteps, setTotalIndivSteps] = useState(0);
  const [startIndividualisation, setStartIndividualisation] = useState(false);
  const [initLoad, setInitLoad] = useState(true);
  const classes = useStyles();
  const [rootx, setRoot] = useState(root);
  const [oldAddress, setOldAddress] = useState("");
  const [useOldCatalogue, setUseOldCatalogue] = useState(false);
  const [oldCatalogueId, setOldCatalogueId] = useState(null);
  const [currentOrder, setCurrentOrder] = useState([]);
  if (totalIndivSteps == 0) {
    deckblaetter.map((deckblatt, index) => {
      if (deckblatt.id == productDeckblatt) {
        setTotalIndivSteps(deckblaetter[index].individualisierungen.length)
      }
    });
  }
  const pages = useMemo(() => {
    if (!useOldCatalogue) {
      const child = new schema.Entity(
        "pages",
        {},
        {
          processStrategy: (entity, parent) => {
            return {
              ...entity,
              parent: parent.id,
              selected: entity.checked
                ? entity.checked == true
                  ? true
                  : false
                : false,
            };
          },
        }
      );
      const children = new schema.Array(child);
      child.define({ children });
      const rootSchema = new schema.Entity("pages", { children });
      return normalize(rootx, rootSchema);
    } else {
      const child = new schema.Entity(
        "pages",
        {},
        {
          processStrategy: (entity, parent) => {
            return {
              ...entity,
              parent: parent.id,
              selected: entity.checked
                ? entity.checked == true
                  ? true
                  : false
                : false,
            };
          },
        }
      );
      const children = new schema.Array(child);
      child.define({ children });
      const rootSchema = new schema.Entity("pages", { children });
      return normalize(rootx, rootSchema);
    }
  }, [useOldCatalogue]);

  const mappedDeckblaetter = useMemo(() => {
    return deckblaetter.map((deckblatt) => {
      return {
        ...deckblatt,
        individualisierungen: deckblatt.individualisierungen.map(
          (individualisierung) => {
            if (
              individualisierung.tag === "Adressblock" &&
              individualisierung.value === ""
            ) {
              return {
                ...individualisierung,
                value: `${activeHaendler.name} ${activeHaendler.name2}
${activeHaendler.street}
${activeHaendler.zip} ${activeHaendler.city}
${activeHaendler.contactHomepage}`,
              };
            } else if (individualisierung.tag === "BJ_Logo") {
              if (individualisierung.value === "") {
                return {
                  ...individualisierung,
                  value: false,
                };
              } else {
                return {
                  ...individualisierung,
                  value: true,
                };
              }
            } else if (
              individualisierung.tag === "Logo" ||
              individualisierung.tag === "Logo_Bottom"
            ) {
              if (individualisierung.value === "") {
                return {
                  ...individualisierung,
                  value: "no",
                };
              }
            } else if (
              individualisierung.tag === "Titel" &&
              individualisierung.value === ""
            ) {
              return {
                ...individualisierung,
                value: product.id == 1901 ? "Bemusterungskatalog" : product.id == 5607 ? "Endkundenanleitung" : "Lösungskatalog",
              };
            }
            return individualisierung;
          }
        ),
      };
    });
  }, [deckblaetter, activeHaendler]);

  const methods = useForm({
    defaultValues: {
      pages,
      deckblaetter: mappedDeckblaetter,
      alteKataloge: productDeckblatt,
      use_custom_back: true,
      use_custom_chapter: false,
      oldAddress: "",
    },
  });

  methods.register("pages", pages);

  const steps = useMemo(() => {
    return [
      "Kapitel",
      "Unterkapitel",
      "Reihenfolge",
      "Titelseite",
      "Vorschau",
    ];
  }, []);

  const myRef = useRef(null);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      myRef.current.scrollIntoView();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setActiveChildStep((prevActiveStep) => 0);
  };
  const [updatedTitle, setUpdatedTitle] = useState("");

  const updatedTitleImage = (
    <div>
      <img src={updatedTitle} />
    </div>
  );

  var intervalId;

  //Submit individualisation information for Titlepage and cusom back
  const onSubmit = (data) => {
    var name = '';
    var address = '';
    var subtitle = '';
    var uploadScale = '100';
    var upload = '';
    var source = '';
    var bjLogo = '';
    var uploadedFile = '';
    var uploadedFileSecond = '';

    if (!event.submitter.matches(".form-control")) {
      var jsonData = JSON.stringify(data);
      let indivIndex;
      data.deckblaetter.map((deckblatt, index) => {
        if (deckblatt.id == productDeckblatt) {
          indivIndex = index;
        }
      });
      data.deckblaetter[indivIndex].individualisierungen.map((indiv) => {
        switch (indiv.tag) {
          case 'Titel':
            name = indiv.value;
            break;
          case 'Adressblock':
            address = indiv.value;
            break;
          case 'Untertitel':
            subtitle = indiv.value;
            break;
          case 'Titelbild_Skalierung':
            uploadScale = indiv.value;
            break;
          case 'Upload':
            upload = indiv.value;
            break;
          case 'Quelle':
            source = indiv.value;
            break;
          case 'BJ_Logo':
            bjLogo = indiv.value == true ? '' : '1';
            break;
          case 'Logo':
            uploadedFile = indiv.value == 'no' || indiv.value == '' ? 'null' : indiv.value + "&deckblatt%5B3%5D%5B1%5D%5Bupload%5D=" + indiv.value;
            break;
          case 'Logo_Bottom':
            uploadedFileSecond = indiv.value == 'no' || indiv.value == '' ? 'null' : indiv.value + "&deckblatt%5B3%5D%5B7%5D%5Bupload%5D=" + indiv.value;
            break;
          default:
            break;
        }
      })
      if (activeStep == 3 && activeChildStep == totalIndivSteps - 1) {
        var useCustomBack = !data.use_custom_back ? 1 : 0;
        var useCustomChapter = data.use_custom_chapter ? 1 : 0;
        var dataToBeSend =
          "deckblatt%5Bid%5D=" + productDeckblatt + "&" +
          "itemId=" + item.id + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B1%5D=" + uploadedFile + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B7%5D=" + uploadedFileSecond + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B2%5D=" + address + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B3%5D=" + name + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B4%5D=" + subtitle + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B5%5D=" + upload + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B8%5D=" + bjLogo + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B10%5D=" + uploadScale + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B9%5D=1095656308&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B6%5D=" + source + "&" +
          "use_custom_back=" + useCustomBack + "&" +
          "use_additional_chapters=" + useCustomChapter + "&" +
          "additional_chapter=8743";
        loadSpinner(null, "Der Katalog wird erstellt.");
        jsonGateway.bapi.startJob(item.id, dataToBeSend, {
          success: () => {
            deactivateSpinner();
            handleNext();
          },
        });
      } else if (activeStep == 3) {
        var dataToBeSend =
          "deckblatt%5Bid%5D=" + productDeckblatt + "&" +
          "itemId=" + data.itemId + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B6%5D=&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B1%5D=" + uploadedFile + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B7%5D=" + uploadedFileSecond + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B2%5D=" + address + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B3%5D=" + name + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B4%5D=" + subtitle + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B8%5D=" + bjLogo + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B5%5D=" + upload + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B10%5D=" + uploadScale + "&" +
          "deckblatt%5B" + productDeckblatt + "%5D%5B9%5D=1095656308&";
        jsonGateway.api.preRenderTitelseite(dataToBeSend);
        loadSpinner(null, "Die Titelseite wird aktualisiert.");
        var refreshCycle = 2000;
        intervalId = window.setInterval(refresh, refreshCycle);
      }
    }
  };

  var busy = false;
  const refresh = () => {
    if (!busy) {
      busy = true;
      jsonGateway.api.getBemusterungskatalogStati(item.id, {
        success: function (el) {
          for (var i in el) {
            if (el[i][".progress"] == 1) {
              deactivateSpinner();
              setUpdatedTitle(
                baseUrl + "/133/disposition/inline/file/" + el[i]["jpg"]
              );
              window.clearInterval(intervalId);
            }
          }
          busy = false;
        },
        error: function (el) {
          busy = false;
          deactivateSpinner();
          window.clearInterval(intervalId);
        },
      });
    }
  };

  const [finishedSteps, setFinishedSteps] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      {initLoad == true && (
        <Grid container xs={12}>
          <Grid item xs={12} style={{ display: "flex" }}>
            {/* <Typography
              variant="subtitle1"
              style={{
                fontSize: "20px",
                fontFamily:
                  "UniversNextW02-Light, Helvetica Neue, Helvetica, Arial",
              }}
            >
              <InfoIcon /> Möchten Sie einen Ihrer letzten Kataloge
              wiederverwenden oder einen neuen Erstellen?
            </Typography> */}
          </Grid>
          <Grid item xs={6}>
            <Button
              style={{ width: "100%", height: "30vh" }}
              onClick={() => {
                setInitLoad(false);
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 style={{ maxWidth: "20vw" }}>
                  bereits erstellten Katalog laden und bearbeiten
                </h1>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p></p>
                  <KeyboardArrowRight />
                </div>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              style={{ width: "100%", height: "30vh" }}
              onClick={() => {
                setStartIndividualisation(true);
                setInitLoad(false);
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1 style={{ maxWidth: "20vw" }}>
                  neuen katalog erstellen und individualisieren
                </h1>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {/* <p>neuen Katalog individualisieren</p> */}
                  <KeyboardArrowRight />
                </div>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      )}
      {startIndividualisation == true ? (
        <BemusterungskatalogContext.Provider
          value={{
            mountParcel,
            accessToken,
            deckblaetter,
            baseUrl,
            destination,
            oldCatalogues,
            attachmentDestination,
            productDeckblatt,
            isAdm,
            admUrl,
            alteKataloge,
            item,
            product,
            logos,
            activeHaendler,
            positions,
            pages,
            jsonGateway,
            url,
            maxSeiten,
          }}
        >
          <IntlProvider messages={messages["de"]} locale="de">
            <DndProvider backend={HTML5Backend}>
              <FormProvider {...methods}>
                <Helmet>
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
                  />
                </Helmet>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  id="bemusterungskatalog"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stepper
                        id="stepperTop"
                        ref={myRef}
                        activeStep={activeStep}
                        alternativeLabel
                      >
                        {steps.map((label, index) => {
                          return (
                            <Step
                              key={label}
                              style={{ cursor: "pointer" }}
                            >
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                    </Grid>
                    {/* Content */}
                    <Grid
                      item
                      xs={12}
                      style={{ height: "100%", minHeight: "11em" }}
                    >
                      {steps[activeStep] == "Titelseite" ? (
                        <Deckblaetter
                          updatedTitle={updatedTitle}
                          activeChildStep={activeChildStep}
                          setActiveChildStep={setActiveChildStep}
                          setFinishedSteps={setFinishedSteps}
                          setOldAddress={setOldAddress}
                          oldAddress={oldAddress}
                        />
                      ) : steps[activeStep] == "Kapitel" ? (
                        <Pages
                          setFinishedSteps={setFinishedSteps}
                          useOldCatalogue={useOldCatalogue}
                        />
                      ) : steps[activeStep] == "Reihenfolge" ? (
                        <Order
                          setCurrentOrder={setCurrentOrder}
                          currentOrder={currentOrder}
                          setFinishedSteps={setFinishedSteps}
                          oldCatalogueId={oldCatalogueId}
                          setOldCatalogueId={setOldCatalogueId}
                        />
                      ) : steps[activeStep] == "Rückseite" ? (
                        <CustomBack oldCatalogueId={oldCatalogueId} />
                      ) : steps[activeStep] == "Vorschau" ? (
                        <Load></Load>
                      ) : (
                        <Subpages setFinishedSteps={setFinishedSteps} />
                      )}
                    </Grid>
                    {/* Back Button */}
                    <Grid item xs={6} align="left">
                      <Button
                        variant="contained"
                        onClick={() => {
                          activeStep === 0
                            ? (setInitLoad(true),
                              setStartIndividualisation(false))
                            : handleBack();
                        }}
                        startIcon={<KeyboardArrowLeft />}
                      >
                        zurück
                      </Button>
                    </Grid>
                    {/* Forward Button */}
                    <Grid item xs={6} align="right">
                      {activeStep < steps.length - 2 && (
                        <>
                          {finishedSteps == true ||
                            activeChildStep == totalIndivSteps - 1 ? (
                            <Button
                              id="nextButton"
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              endIcon={<KeyboardArrowRight />}
                            >
                              weiter
                            </Button>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                      {activeStep === steps.length - 2 && activeChildStep == totalIndivSteps - 1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          startIcon={<ImportContactsIcon />}
                        >
                          Katalog erzeugen
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </DndProvider>
          </IntlProvider>
        </BemusterungskatalogContext.Provider>
      ) : (
        <BemusterungskatalogContext.Provider
          value={{
            mountParcel,
            accessToken,
            deckblaetter,
            baseUrl,
            destination,
            oldCatalogues,
            attachmentDestination,
            productDeckblatt,
            isAdm,
            admUrl,
            alteKataloge,
            item,
            product,
            logos,
            activeHaendler,
            positions,
            pages,
            jsonGateway,
            url,
            maxSeiten,
          }}
        >
          <FormProvider {...methods}>
            <OldCatalogue
              showOldCatalogues={!initLoad}
              setRoot={setRoot}
              setOldCatalogueId={setOldCatalogueId}
              setOldAddress={setOldAddress}
              oldAddress={oldAddress}
              setStartIndividualisation={setStartIndividualisation}
              setUseOldCatalogue={setUseOldCatalogue}
            />
          </FormProvider>
        </BemusterungskatalogContext.Provider>
      )}
      {startIndividualisation == false && initLoad == false && (
        <Grid item xs={6} align="left">
          <Button
            variant="contained"
            onClick={() => {
              activeStep === 0
                ? (setInitLoad(true), setStartIndividualisation(false))
                : handleBack();
            }}
            startIcon={<KeyboardArrowLeft />}
          >
            zurück
          </Button>
        </Grid>
      )}
    </ThemeProvider>
  );
}
