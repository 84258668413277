import React, { useState, useMemo, useEffect, useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Card from "./components/Card";
import Typography from "@mui/material/Typography";
import { BemusterungskatalogContext } from "../../root.component";
import InfoIcon from "@mui/icons-material/Info";

export default function Page(props) {
  const { setValue } = useFormContext();
  const [wait, setWait] = useState(false);
  props.setFinishedSteps(false);
  const { jsonGateway, item } = useContext(BemusterungskatalogContext);
  const pages = props.useOldCatalogue
    ? useContext(BemusterungskatalogContext).pages
    : useWatch({ name: "pages" });
  if (props.useOldCatalogue == true) {
    document.body.style.cursor = "wait";
    // setWait(true);
    // map and perform jsonGateway change on selected true
    // perform state update on pages memo
    for (const [key, value] of Object.entries(pages.entities.pages)) {
      if (
        value.selected == true &&
        (value.parent == 1 || value.parent == 889)
      ) {
        let newData = JSON.parse(JSON.stringify(pages));
        setValue("pages", newData);
        jsonGateway.bapi.change(item.id, value.id, "checked", true, {
          success: () => {
            document.body.style.cursor = "default";
            // setWait(false);
          },
        });
      }
    }
  }

  const [data, setData] = useState(pages);
  const [selectedPages, setSelectedPages] = useState([]);

  if (selectedPages.length > 0) {
    props.setFinishedSteps(true);
  }

  //Add Catalogue Pages
  const addPages = (element) => {
    document.body.style.cursor = "wait";
    setWait(true);
    jsonGateway.bapi.change(item.id, element.id, "checked", true, {
      success: () => {
        document.body.style.cursor = "default";
        setWait(false);
      },
    });
    const selectChilds = (id) => {
      const element = data.entities.pages[id];
      element.selected = true;
      if (element?.children?.length > 0) {
        element.children.map((childId) => {
          data.entities.pages[childId].selected = true;
          selectChilds(childId);
        });
      }
    };
    data.entities.pages[element.parent].selected = true;
    if (element.parent != 1 || element.parent != 889) {
      setSelectedPages((pages) => [
        ...pages,
        {
          parent: data.entities.pages[element.parent].parent,
          children: data.entities.pages[element.parent].children,
          hasActiveChildren: true,
          label: data.entities.pages[element.parent].label,
        },
      ]);
    }
    if (element.children.length > 0) {
      selectChilds(element.id);
      setSelectedPages((pages) => [
        ...pages,
        {
          parent: element.id,
          children: element.children,
          hasActiveChildren: element.children.length > 0,
          label: element.label,
        },
      ]);
    } else {
      setSelectedPages((selectedPages) => [
        ...selectedPages,
        { parent: element.id, children: [] },
      ]);
    }
  };

  //Remove Catalogue Pages
  const removePages = (element) => {
    jsonGateway.bapi.change(item.id, element.id, "checked", false, {});
    const selectChilds = (id) => {
      const element = data.entities.pages[id];
      element.selected = false;
      if (element?.children?.length > 0) {
        element.children.map((childId) => {
          data.entities.pages[childId].selected = false;
          selectChilds(childId);
        });
      }
    };
    //Stackoverflow solution to remove element from array
    function removeByKey(key, arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].parent == key || arr[i] == key) {
          arr.splice(i, 1);
        } else if (typeof arr[i].children !== "undefined") {
          removeByKey(key, arr[i].children);
        }
      }
    }
    selectChilds(element.id);
    data.entities.pages[element.id].selected = false;
    let selectedPagesWithDelete = selectedPages;
    removeByKey(element.id, selectedPagesWithDelete);
    setSelectedPages(selectedPagesWithDelete);
  };

  const getSelectedPages = (id, element) => {
    element.selected ? removePages(element) : addPages(element);
  };

  useEffect(() => {
    setData(pages);
  }, [pages]);

  const [currentElement, setCurrentElement] = React.useState(data?.result);

  const getIds = (ids = [], id) => {
    if (data?.entities?.pages) {
      let element = data.entities.pages[id];
      ids.push(id);
      if (element?.children?.length > 0) {
        element.children.map((childId) => {
          ids = getIds(ids, childId);
        });
      }
    }

    return ids;
  };

  const getCompleteSelected = (id) => {
    const ids = getIds([], id);
    let selected = true;
    ids.forEach((subId) => {
      if (!data?.entities?.pages[subId].selected) {
        selected = false;
      }
    });
    return selected;
  };

  if (!data || !currentElement) {
    return null;
  }

  props.setFinishedSteps(true);

  return (
    <Grid container spacing={2}>
      {/* Breadcrumbs to go back */}
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          style={{
            fontSize: "20px",
            fontFamily:
              "UniversNextW02-Light, Helvetica Neue, Helvetica, Arial",
          }}
        >
          <InfoIcon /> Wählen Sie alle Kapitel aus, welche in Ihrem Katalog
          enthalten sein sollen.
        </Typography>
      </Grid>

      {/* Page Grid */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {data.entities.pages[currentElement]?.children.map((id, index) => {
            const element = data.entities.pages[id];
            const completeSelected = getCompleteSelected(id);

            const handleSelectClick = (e) => {
              if (wait == false) {
                e.preventDefault();
                e.stopPropagation();
                const value = completeSelected ? false : true;
                let newData = JSON.parse(JSON.stringify(data));
                let ids = getIds([], id);

                if (completeSelected) {
                  ids.forEach((subId) => {
                    newData.entities.pages[subId].selected = false;
                  });
                } else {
                  ids.forEach((subId) => {
                    newData.entities.pages[subId].selected = value;
                  });
                }
                setValue("pages", newData);
                getSelectedPages(id, element);
              }
            };
            return (
              <Card
                wait={wait}
                key={id}
                element={element}
                index={index}
                chapter={true}
                selected={element?.selected}
                completeSelected={completeSelected}
                handleSelectClick={handleSelectClick}
              />
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
