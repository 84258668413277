import React, { useContext, useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@material-ui/core/Button";

import { BemusterungskatalogContext } from "../../../root.component";
import Dropzone from "../../Dropzone";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
    padding: theme.spacing(1),
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Logo_Bottom({
  individualisierung = { tag: "Logo_Bottom" },
  deckblattIndex,
  activeStep,
  updatedAddress,
}) {
  const classes = useStyles();
  const { control } = useFormContext();
  const { logos, baseUrl, destination, jsonGateway, isAdm } = useContext(
    BemusterungskatalogContext
  );
  const logo = useWatch({
    control,
    name: "logo_bottom",
  });
  const [uploadLogo, setUploadLogo] = useState([]);
  const [uploadedLogo, setUploadedLogo] = useState("");
  const [isDropped, setIsDropped] = useState(false);
  const [preview, setPreview] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [uploadOtherLogo, setUploadOtherLogo] = useState(false);
  const [customerLogos, setCustomerLogos] = useState([]);
  const [selected, setSelected] = useState(false);
  // const [searchedLogo, setSearchedLogo] = useState();

  if (logos[1] != undefined && uploadLogo.length == 0) {
    setUploadLogo(logos[1].file);
    setUploadedLogo(logos[1].id);
  }
  const onDrop = useCallback((acceptedFiles) => {
    const sendReq = async () => {
      var xhr = new XMLHttpRequest();
      if (!xhr.upload) {
        return false;
      }
      xhr.open("POST", destination, true);
      xhr.setRequestHeader("X-FILENAME", acceptedFiles[0].name);
      xhr.setRequestHeader("X-ATTACHMENTNAME", acceptedFiles[0].name);

      xhr.upload.addEventListener(
        "progress",
        (e) => {
          var percentageCompleted = parseInt(
            (parseInt(e.loaded) / parseInt(e.total)) * 100
          );
        },
        false
      );

      //Dont know what is happening here
      xhr.onreadystatechange = (e) => {
        if (e.target.readyState == 4 && e.target.status == 200) {
          setUploadLogo(
            acceptedFiles.map((file) =>
              Object.assign(file, { preview: URL.createObjectURL(file) })
            )
          );
          logos[1] = {
            file: acceptedFiles.map((file) =>
              Object.assign(file, { preview: URL.createObjectURL(file) })
            ),
            id: e.target.responseText,
          };
          setPreview(acceptedFiles[0].preview);
          setIsDropped(true);
          setUploadedLogo(e.target.responseText);
          return e.target.responseText;
        } else if (e.target.readyState == 4 && e.target.status == 500) {
          setSnackbarOpen(true);
          setSnackbarText(e.target.responseText);
        }
      };
      xhr.send(acceptedFiles[0]);
    };
    sendReq();
  }, []);

  const thumbs = uploadLogo.map((file) => (
    <div
      key={file.name}
      style={{
        border: "1px solid rgb(204, 204, 204)",
        height: "250px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img src={file.preview} alt={file.name} style={{ height: "100%" }} />
    </div>
  ));

  // clean up
  // useEffect(
  //   () => () => {
  //     uploadLogo.forEach((file) => URL.revokeObjectURL(file.preview));
  //   },
  //   [uploadLogo]
  // );

  useEffect(() => {
    setCustomerLogos([]);
    if (updatedAddress != "") {
      jsonGateway.api.loadLogoAsyncByCustomer([updatedAddress.street, updatedAddress.city], {
        success: (data) => {
          if (data != null) {
            setUploadedLogo("customerLogo#" + data.id);
            setIsDropped(true);
            setSelected(data.id);
            setUploadLogo([{ preview: data.file, name: data.id }]);
            setCustomerLogos((prev) => [
              ...prev,
              {
                image: data.file,
                id: data.id,
                city: updatedAddress.city,
                name: updatedAddress.name,
              },
            ]);
          } else {
            setUploadLogo([]);
          }
        },
      });
    }
  }, [updatedAddress]);

  return (
    <Grid container spacing={2}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          setSnackbarOpen(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{snackbarText}</Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          <FormattedMessage
            id={`source.application.modules.frontend.views.scripts.katalog.individualisierung.tags.${individualisierung.tag.toLowerCase()}`}
            defaultMessage="Logo unten links"
          />
        </Typography>
      </Grid>
      <Controller
        render={({ field }) => {
          return (
            <RadioGroup {...field} className={classes.fullWidth}>
              <Grid container>
                <Grid item xs={12}>
                  <FormControlLabel
                    value={"no"}
                    control={<Radio color="primary" />}
                    onClick={() => setUploadOtherLogo(false)}
                    label={
                      <FormattedMessage
                        id="source.application.modules.frontend.views.scripts.katalog.individualisierung.logo.logonotprint"
                        defaultMessage="Logo nicht eindrucken"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    disabled={
                      logos[0] == undefined
                        ? customerLogos.length > 0
                          ? false
                          : true
                        : false
                    }
                    value={uploadedLogo}
                    id={uploadLogo}
                    control={<Radio color="primary" />}
                    onClick={(e) => setUploadOtherLogo(true)}
                    label={
                      <FormattedMessage
                        id="source.application.modules.frontend.views.scripts.katalog.individualisierung.logo.logoupload"
                        defaultMessage="Eigenes Logo eindrucken:"
                      />
                    }
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          );
        }}
        name={`deckblaetter[${deckblattIndex}].individualisierungen[${activeStep}].value`}
        control={control}
      />
      <Grid item xs={12} className="thumbnail-holder active-upload">
        <Typography variant="p" gutterBottom>
          Eigenes Logo Hochladen
        </Typography>
        <Dropzone
          id="custom_logo"
          options={{
            onDrop,
            accept: ".jpeg,.png,.tiff,.tif,.gif,.jpg",
            dropped: isDropped,
          }}
          tokenDrag="Datei per drag & drop hier ablegen. Mögliche Dateitypen sind: jpeg, gif, tiff, png"
          tokenDrop="Bitte legen Sie die Dateien hier ab."
        />
      </Grid>
      {true == false && (
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Logos meiner Händler
          </Typography>
          {customerLogos.length <= 0 ? (
            <Button
              onClick={() => {
                jsonGateway.api.fetchLogos({
                  success: (data) => {
                    setCustomerLogos([]);
                    data.map((logo) => {
                      jsonGateway.api.loadLogoAsync(logo.logoId, {
                        success: (data) => {
                          setCustomerLogos((prev) => [
                            ...prev,
                            {
                              image: data,
                              id: logo.logoId,
                              city: logo.city,
                              name: logo.name,
                            },
                          ]);
                        },
                      });
                    });
                  },
                });
              }}
            >
              Laden
            </Button>
          ) : (
            <Button
              onClick={() => {
                setCustomerLogos([]);
              }}
            >
              Schließen
            </Button>
          )}
        </Grid>
      )}
      {/* <Grid container xs={12}>
        {customerLogos.length != 0 && (
          <>
            <TextField
              id="customerLogoSearch"
              label="Suche..."
              onChange={(e) => {
                setSearchedLogo(
                  customerLogos.find(
                    (element) =>
                      element.city.toLowerCase() ==
                        e.target.value.toLowerCase() ||
                      element.name.toLowerCase() == e.target.value.toLowerCase()
                  )
                );
              }}
            />
            {searchedLogo != undefined ? (
              <Grid
                item
                xs={3}
                spacing={2}
                id={searchedLogo.id}
                style={{
                  maxHeight: "4em",
                  padding: ".5em",
                  border:
                    selected == searchedLogo.id ? "2px solid #009fe3" : "",
                }}
              >
                <img
                  src={searchedLogo.image}
                  alt={searchedLogo.id}
                  style={{
                    height: "100%",
                  }}
                  onClick={() => {
                    setUploadedLogo("customerLogo#" + searchedLogo.id);
                    setIsDropped(true);
                    setSelected(searchedLogo.id);
                  }}
                />
              </Grid>
            ) : (
              <>
                {customerLogos.map((logo) => (
                  <Grid
                    item
                    xs={3}
                    spacing={2}
                    id={logo.id}
                    style={{
                      maxHeight: "4em",
                      padding: ".5em",
                      border: selected == logo.id ? "2px solid #009fe3" : "",
                    }}
                  >
                    <img
                      src={logo.image}
                      alt={logo.id}
                      style={{
                        height: "100%",
                      }}
                      onClick={() => {
                        setUploadedLogo("customerLogo#" + logo.id);
                        setIsDropped(true);
                        setSelected(logo.id);
                      }}
                    />
                  </Grid>
                ))}
              </>
            )}
          </>
        )}
      </Grid> */}
      <Grid item xs={12}>
        {thumbs}
      </Grid>
    </Grid>
  );
}
