import React, { useContext, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { BemusterungskatalogContext } from "../../../root.component";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export default function Adressblock({
  individualisierung = { tag: "Adressblock" },
  deckblattIndex,
  activeStep,
  updatedAddress,
  setUpdatedAddress,
  showCustomersearch,
}) {

  const { control, setValue } = useFormContext();
  const {
    activeHaendler
  } = useContext(BemusterungskatalogContext);

  useEffect(() => {
    if (updatedAddress != "") {
      let el = document.getElementById("edit");
      el.value = `${updatedAddress.name}
${updatedAddress.street}
${updatedAddress.zipCity}`;
      el.dispatchEvent(new Event("input", { bubbles: true }));
      setValue(`deckblaetter[${deckblattIndex}].individualisierungen[${activeStep}].value`, updatedAddress.address, { shouldValidate: false })
    }
  }, [updatedAddress]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography id="typo" variant="h4" gutterBottom>
          <FormattedMessage
            id={`source.application.modules.frontend.views.scripts.katalog.individualisierung.tags.${individualisierung.tag.toLowerCase()}`}
            defaultMessage="Adressblock"
          />
        </Typography>
      </Grid>
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12}>
              <TextField
                id="edit"
                multiline
                variant="filled"
                maxRows={4}
                {...field}
              />
            </Grid>
          );
        }}
        name={`deckblaetter[${deckblattIndex}].individualisierungen[${activeStep}].value`}
        control={control}
      />
    </Grid>
  );
}
