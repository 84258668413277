import React, { useContext, useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { BemusterungskatalogContext } from "../../root.component";
import Dropzone from "../Dropzone";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomBack({ oldCatalogueId }) {
  const { jsonGateway, item, attachmentDestination } = useContext(
    BemusterungskatalogContext
  );
  const { control } = useFormContext();
  const [showUpload, setShowUpload] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [uploadPdf, setUploadPdf] = useState([]);
  const [isDropped, setIsDropped] = useState(false);
  const [preview, setPreview] = useState(null);
  const [uploadedLogo, setUploadedLogo] = useState("");

  useEffect(() => {
    if (oldCatalogueId != null) {
      jsonGateway.api.getAdditionalAttachment(oldCatalogueId, true, {
        success: (data) => {
          setIsDropped(true);
          data.map((object) => {
            setUploadedLogo(object.file_file_id);
          });
        },
      });
    }
  }, []);
  const onDrop = useCallback((acceptedFiles) => {
    const sendReq = async () => {
      var xhr = new XMLHttpRequest();
      if (!xhr.upload) {
        return false;
      }
      xhr.open("POST", attachmentDestination, true);
      xhr.setRequestHeader("X-FILENAME", acceptedFiles[0].name);
      xhr.setRequestHeader("X-ATTACHMENTNAME", acceptedFiles[0].name);

      //Dont know what is happening here
      xhr.onreadystatechange = (e) => {
        if (e.target.readyState == 4 && e.target.status == 200) {
          setUploadPdf(
            acceptedFiles.map((file) =>
              Object.assign(file, { preview: URL.createObjectURL(file) })
            )
          );
          setPreview(acceptedFiles[0].preview);
          setIsDropped(true);
          setUploadedLogo(e.target.responseText);
          // jsonGateway.bapi.getBemusterungskatalogSeitenzahlen(item.id);
          return e.target.responseText;
        } else if (e.target.readyState == 4 && e.target.status == 500) {
          setSnackbarOpen(true);
          setSnackbarText(e.target.responseText);
        }
      };
      xhr.send(acceptedFiles[0]);
    };
    sendReq();
  }, []);

  const thumbs = uploadPdf.map((file) => (
    <div key={file.name}>
      <img src={file.preview} alt={file.name} />
    </div>
  ));

  // clean up
  useEffect(
    () => () => {
      uploadPdf.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [uploadPdf]
  );

  return (
    <Grid container spacing={2}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={(event, reason) => {
          setSnackbarOpen(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{snackbarText}</Alert>
      </Snackbar>
      <Grid item xs={9}>
        <Typography
          variant="subtitle1"
          style={{
            fontSize: "20px",
            fontFamily:
              "UniversNextW02-Light, Helvetica Neue, Helvetica, Arial",
          }}
        >
          <InfoIcon /> Legen Sie fest, welche Rückseite Sie verwenden möchten.
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Controller
          value={uploadedLogo}
          name="use_custom_back"
          control={control}
          render={({ field }) => {
            return (
              <FormControlLabel
                value={uploadedLogo}
                control={
                  <Checkbox
                    value={uploadedLogo}
                    color="primary"
                    {...field}
                    onClick={() => {
                      setShowUpload(!showUpload);
                    }}
                    defaultChecked={true}
                  />
                }
                label={
                  <FormattedMessage
                    id="source.application.modules.frontend.views.scripts.cart.detail.bemusterungskatalog.attachment.use"
                    defaultMessage="Soll die Standard Rückseite verwendet werden?"
                  />
                }
              />
            );
          }}
        />
      </Grid>
      {showUpload && (
        <Grid item xs={6}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              style={{
                fontSize: "20px",
                fontFamily:
                  "UniversNextW02-Light, Helvetica Neue, Helvetica, Arial",
              }}
            >
              <InfoIcon /> Laden Sie eine PDF-Datei hoch, welche Sie als
              Rückseite verwenden möchten.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Dropzone
              id="custom_backs"
              value={uploadedLogo}
              control={control}
              options={{
                onDrop,
                accept: ".pdf",
                dropped: isDropped,
              }}
              tokenDrag="Dateien per drag & drop hier ablegen. Mögliche Dateitypen sind: PDF."
              tokenDrop="Bitte legen Sie die Dateien hier ab."
            />
          </Grid>
          <Grid item xs={12}>
            {thumbs}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
