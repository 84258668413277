import React from "react";
import { FormattedMessage } from "react-intl";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function Dropzone({ options, tokenDrag, tokenDrop, id, infoLine }) {
  const { register } = useFormContext();
  const { getRootProps, getInputProps, isDragActive } = useDropzone(options);
  const handleFileInput = (e) => {
    let acceptedFile = e.target.files;
    options.onDrop(acceptedFile);
  };
  const onDropImg = () => {
    const uploadFileEle = document.getElementById("fileInput");
    uploadFileEle.click();
  };
  return (
    <Paper variant="outlined" elevation={3} square style={{ padding: 8 }}>
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleFileInput}
      />
      <div
        {...getRootProps()}
        onClick={() => {
          onDropImg();
        }}
        style={{ cursor: "pointer" }}
      >
        <input {...getInputProps()} {...register(id)} />
        <Grid container spacing={1}>
          <Grid item xs={12} align="center">
            {isDragActive ? (
              <AddCircleIcon fontSize="large" />
            ) : (
              <>
                {options.dropped == true ? (
                  <CheckCircleIcon fontSize="large" />
                ) : (
                  <AddCircleOutlineIcon fontSize="large" />
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} align="center">
            {isDragActive ? (
              <Typography>
                <FormattedMessage id={tokenDrop} />
              </Typography>
            ) : (
              <>
                <Typography>
                  <FormattedMessage id={tokenDrag} />
                </Typography>
                {infoLine && (
                  <Typography>
                    <FormattedMessage id={infoLine} />
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
