import React from "react";
import { FormattedMessage } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slider from "@mui/material/Slider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

export default function Titelbild_Skalierung({
  individualisierung = { tag: "Titelbild_Skalierung", value: 100 },
  deckblattIndex,
  activeStep,
}) {
  const { control } = useFormContext();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          <FormattedMessage
            id={`source.application.modules.frontend.views.scripts.katalog.individualisierung.upload.skalierung`}
            defaultMessage={"Titelbild Skalierung"}
          />
        </Typography>
      </Grid>
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12}>
              <TextField
                id="textFieldAdornment"
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
          );
        }}
        name={`deckblaetter[${deckblattIndex}].individualisierungen[${activeStep}].value`}
        control={control}
      />
    </Grid>
  );
}
