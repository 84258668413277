import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import { BemusterungskatalogContext } from "../../root.component";

export default function Load() {
  const { jsonGateway, item } = useContext(BemusterungskatalogContext);
  const [finishedCatalogue, setFinishedCatalogue] = useState(false);
  const [pdf, setPdf] = useState();
  const [jpg, setJpg] = useState();

  var intervalId;
  var busy = false;
  loadSpinner(null, "Der Katalog wird erstellt.");

  const refresh = () => {
    if (!busy) {
      busy = true;
      jsonGateway.api.getBemusterungskatalogStati(item.id, {
        success: function (el) {
          for (var i in el) {
            if (el[i][".progress"] == 1) {
              deactivateSpinner();
              setFinishedCatalogue(true);
              setPdf(el[i].pdf);
              setJpg(el[i].jpg);
              window.clearInterval(intervalId);
            }
          }
          busy = false;
        },
        error: function (el) {
          busy = false;
          deactivateSpinner();
          window.clearInterval(intervalId);
        },
      });
    }
  };

  refresh();
  var refreshCycle = 2000;
  intervalId = window.setInterval(refresh, refreshCycle);

  return (
    <Grid container spacing={2}>
      {finishedCatalogue && (
        <>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={"/133/disposition/inline/file/" + jpg}
              style={{ border: "1px solid rgb(204, 204, 204)" }}
            ></img>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <a target="_blank" href={"/133/disposition/inline/file/" + pdf}>
                <Button variant="contained">zur PDF Vorschau</Button>
              </a>
              <a target="_blank" href={"/14/"}>
                <Button variant="contained">zum Warenkorb</Button>
              </a>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
