import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

export default function Title({
  individualisierung = { tag: "Title" },
  deckblattIndex,
  activeStep,
}) {
  const { control } = useFormContext();
  const intl = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          <FormattedMessage
            id={`source.application.modules.frontend.views.scripts.katalog.individualisierung.tags.${individualisierung.tag.toLowerCase()}`}
            defaultMessage="Individueller Titel"
          />
        </Typography>
      </Grid>
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12}>
              <TextField
                {...field}
                variant="filled"
                placeholder="Bemusterungskatalog"
              />
            </Grid>
          );
        }}
        name={`deckblaetter[${deckblattIndex}].individualisierungen[${activeStep}].value`}
        control={control}
      />
    </Grid>
  );
}
