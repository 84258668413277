import React from "react";
import { FormattedMessage } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

export default function Quelle({
  individualisierung = { tag: "Quelle", value: "" },
  deckblattIndex,
  activeStep,
}) {
  const { control } = useFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          <FormattedMessage
            id={`source.application.modules.frontend.views.scripts.katalog.individualisierung.tags.${individualisierung.tag.toLowerCase()}`}
            defaultMessage="Quelle"
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom>Bitte beachten Sie die Bildrechte</Typography>
        <Typography gutterBottom>
          Sollten Sie bei dem Bild für die Titelseite zur Angabe eine Quelle
          vepflichtet sein, so tragen Sie diese Angabe bitte hier ein. Der Text
          wird auf der Rückseite unten rechts positioniert. Sollten Sie für die
          Rückseite eine eigene Vorlage verwenden, nehmen sie die Quellangabe in
          Ihrer Vorlage bitte selbständig vor.
        </Typography>
      </Grid>
      <Controller
        render={({ field }) => {
          return (
            <Grid item xs={12}>
              <TextField {...field} placeholder="z.B. Fotolia.de" />
            </Grid>
          );
        }}
        name={`deckblaetter[${deckblattIndex}].individualisierungen[${activeStep}].value`}
        control={control}
      />
    </Grid>
  );
}
